import React, { useEffect, useState } from "react";
import "./style.css";
import { useNavigate,useParams,Link } from "react-router-dom";
import { AxiosPrivate as axios } from "../axios/axios";
import "react-toastify/dist/ReactToastify.css";


const ViewCompany = (props) => {
    const navigate = useNavigate();
    let params = useParams();
    const [companyDetails, setCompanyDetails] = useState({});
    const [imagePreview, setImagePreview] = useState("https://dummyimage.com/100x100/ddd/000");

    useEffect(() => {
        axios
        .get(`${process.env.REACT_APP_API_URL}/banner/getCompanyById`, {
            params: {
                CompanyId: `${params.id}`,
            },
        })
        .then((res) => {
            if (res?.data?.response?.status === "success") {
                setCompanyDetails(res?.data?.response?.company?.details);
                setImagePreview(res?.data?.response?.company?.logo_image_url);

            }

        });
    }, [params.id]);


    const editDetails = async (e) => {
        navigate(`/edit-company/${params.id}`);
    }




    return (
        <>

            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Companies</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/companyList">Companies</Link>

                                </li>
                                <li className="breadcrumb-item active" aria-current="page">View Company</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="col-12 row">
                                <div className="p-3 col-10">

                                    <h5 className="mb-0">View Company Details</h5>

                                </div>
                                <div className="p-3  col-2">
                                <button class="form-control  btn btn-outline-default waves-effect"  onClick={editDetails}>Edit company Details</button>
                        
                                </div>
                            </div>

                            {/* {showErrorNotification ?
                                <div className="alert alert-danger col-4" role="alert">
                                    {notificationError}
                                </div> : null} */}

                            <div className="p-3">
                                <form action="">
                                    <div className="row">
                                        <div className="col-6">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>Company Details</h5></legend>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Company Name</b> </label>
                                                    <p >{companyDetails.company_name}</p>
                                                </div>


                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>License Number</b></label>
                                                    <p >{companyDetails.license_number}</p>
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Contact Number</b> </label>

                                                    <p>{companyDetails.contact_number}</p>
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Email</b> </label>

                                                    <p>{companyDetails.email}</p>
                                                </div>
                                            </fieldset>

                                        </div>
                                        <div className="col-6">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>Company Address:</h5></legend>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Address</b> </label>

                                                    <p> {companyDetails.address}</p>
                                                </div>


                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>City</b> </label>

                                                    <p >{companyDetails.city}</p>
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>State</b> </label>

                                                    <p>{companyDetails.state}</p>
                                                </div>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Zip Code</b></label>

                                                    <p>{companyDetails.zip_code}</p>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-12">
                                        <fieldset className="border rounded-3 p-3">
                                            <legend className="float-none w-auto px-3"></legend>
                                            <div className="row">
                                                <div className="col-6">

                                                    <div className="mb-3 col-4">
                                                        <label className="form-label"><b>Company Status </b></label>
                                                        <p className="text-success"> {companyDetails.status === true ? "Active" : "In-Active"}</p>

                                                    </div>

                                                </div>
                                                <div className="col-6">
                                                    <div className="mb-3 col-4">
                                                        <label className="form-label"><b>Upload Logo</b></label>
                                                    </div>
                                                    <div className="mb-3 col-4">
                                                        <div className="rounded col-md-4 text-left"  >
                                                            <img src={imagePreview} className="rounded w-100" alt="..." />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        </div>
                                    </div>
                                  </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>


    );
};

export default ViewCompany;
