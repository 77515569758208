import React, { useState } from "react";
import validator from 'validator';
import "./style.css";
import { useNavigate } from "react-router-dom";
import { AxiosPrivate as axios } from "../axios/axios";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CreateCompany = (props) => {
    const navigate = useNavigate();
    const [selectedFile1, setSelectedFile1] = useState([]);
    const [companyName, setComapnyName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [licenseNumber, setLicenseNumber] = useState("");
    const [status, setStatus] = useState("Inactive");
    const [stateName, setStateName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [imagePreview, setImagePrevie] = useState("https://dummyimage.com/100x100/ddd/000");
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [notificationError, setNotificationError] = useState("");
    const [awsSuccess, setAwsSuccess] = useState(false);
    const [logoPath,setLogoPath]=useState("");

    const onSelectFile1 = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile1(undefined);
        }
        else {

            let filePath = Date.now() + e.target.files[0].name;
            setAwsSuccess(false);
            setSelectedFile1(e.target.files[0]);
            setLogoPath(filePath);
            setImagePrevie(URL.createObjectURL(e.target.files[0]));
        }

    };


    const handleOptionChange = (e) => {
        setStatus(e.target.value);
    };


    const validateForm = () => {
        let isFormValid = true;
        const formErrors = {};
        if (!companyName) {
            formErrors.companyName = "Please enter company name";
            isFormValid = false;
        }
        if (!licenseNumber) {
            formErrors.licenseNumber = "Please enter license number";
            isFormValid = false;
        }
        if (!contactNumber) {
            formErrors.contactNumber = "Please enter contact number";
            isFormValid = false;
        }
        if (!email) {
            formErrors.email = "Please enter email";
            isFormValid = false;
        }
        else {
            if (!validator.isEmail(email)) {
                formErrors.email = "Please enter valid email";
                isFormValid = false;
            }
        }

        if (!address) {
            formErrors.address = "Please enter address";
            isFormValid = false;
        }

        if (!city) {
            formErrors.city = "Please enter city";
            isFormValid = false;
        }
        if (!zip) {
            formErrors.zip = "Please enter zip code";
            isFormValid = false;
        }
        if (!stateName) {
            formErrors.stateName = "Please enter state";
            isFormValid = false;
        }
        setErrors(formErrors);
        return isFormValid;
    };

    const createCompany = async (formData) => {
        axios.post(`${process.env.REACT_APP_API_URL}/banner/createcompany`, formData).then((res) => {
            let status = res?.data?.response?.status;

            if (status === "success") {
                setShowErrorNotification(false);

                let uploadImage1 = document.getElementById("img1");
                uploadImage1.value = "";
                toast.success(res?.data?.response?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


                navigate("/companyList");
            }
            else {
                window.scrollTo(0, 0);
                setShowErrorNotification(true);
                setNotificationError(res.data.response.message)
                // toast("Somethiing went wrong!");
            }

            //return res;
        }).catch((err) => {
            window.scrollTo(0, 0);
            setShowErrorNotification(true);
            setNotificationError("Something went wrong... Please contact admin!!!");
        });

    }
    const onSubmitClick = async (e) => {
        e.preventDefault();
        if (validateForm()) {

            
            let image = selectedFile1;
            let formData = {
                CompanyName: companyName,
                LicenseNumber: licenseNumber,
                ImagePath: logoPath,
                ContactNumber: contactNumber,
                Email: email,
                City: city,
                Address: address,
                State: stateName,
                Status: status,
                ZipCode: zip
            };
            if (!selectedFile1.name) {
                createCompany(formData);
            }
            else {
                debugger;
                if (awsSuccess) {
                    createCompany(formData);
                }
                else {
                    await axios.get(`${process.env.REACT_APP_API_S3_URL}/v1/s3/put-presigned-url?fileName=${logoPath}`).then((res) => {
                        const { data } = res;
                        const url = data.url;
                        if (url !== "") {
                            
                            axios({
                                method: "put",
                                url: url,
                                data: image,
                            }).then((res) => {
                                setAwsSuccess(true);
                                createCompany(formData);
                            }).catch((err) => {
                                window.scrollTo(0, 0);
                                setAwsSuccess(false);
                                setShowErrorNotification(true);
                                setNotificationError("Something went wrong... Please contact admin!!!");
                            });

                        }
                        else {
                            window.scrollTo(0, 0);
                            setShowErrorNotification(true);
                            setNotificationError("Something Went Wrong!Please contact Admin!!!")
                        }

                    }).catch((err) => {
                        window.scrollTo(0, 0);
                        setAwsSuccess(false);
                        setShowErrorNotification(true);
                        setNotificationError("Something went wrong... Please contact admin!!!");
                    });
                }
            }
        }
    };



    return (
        <>

            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Companies</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/companyList">Companies</Link>

                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Create Company</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="p-3">

                                <h5 className="mb-0">Create Company</h5>

                            </div>
                            {showErrorNotification ?
                                <div className="alert alert-danger col-4" role="alert">
                                    {notificationError}
                                </div> : null}

                            <div className="p-3">
                                <form action="">
                                    <div className="row">
                                        <div className="col-6">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>Company Details</h5></legend>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Company Name</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="companyName" placeholder="Enter Company Name" value={companyName} onChange={e => setComapnyName(e.target.value)} />
                                                    {errors.companyName && <p className="errorMsg">{errors.companyName}</p>}
                                                </div>


                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>License Number</b> <i className="star">*</i></label>
                                                    <input type="text" className="form-control" name="url"
                                                        placeholder="Enter License Number "
                                                        value={licenseNumber}
                                                        onChange={e => setLicenseNumber(e.target.value)} />
                                                    {errors.licenseNumber && <p className="errorMsg">{errors.licenseNumber}</p>}
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Contact Number</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="contactNumber" placeholder="Enter Contact Number" value={contactNumber} onChange={e => setContactNumber(e.target.value)} />
                                                    {errors.contactNumber && <p className="errorMsg">{errors.contactNumber}</p>}
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Email</b> <i className="star">*</i></label>

                                                    <input type="email" className="form-control" name="email" placeholder="Enter email id" value={email} onChange={e => setEmail(e.target.value)} />
                                                    {errors.email && <p className="errorMsg">{errors.email}</p>}
                                                </div>
                                            </fieldset>

                                        </div>
                                        <div className="col-6">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>Company Address:</h5></legend>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Address</b> <i className="star">*</i></label>

                                                    <input type="email" className="form-control" name="address" placeholder="Enter Address" value={address} onChange={e => setAddress(e.target.value)} />
                                                    {errors.address && <p className="errorMsg">{errors.address}</p>}
                                                </div>


                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>City</b> <i className="star">*</i></label>

                                                    <input type="email" className="form-control" name="city" placeholder="Enter City" value={city} onChange={e => setCity(e.target.value)} />
                                                    {errors.city && <p className="errorMsg">{errors.city}</p>}
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>State</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="stateName" placeholder="Enter state" value={stateName} onChange={e => setStateName(e.target.value)} />
                                                    {errors.stateName && <p className="errorMsg">{errors.stateName}</p>}
                                                </div>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Zip Code</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="stateName" placeholder="Enter zip code" value={zip} onChange={e => setZip(e.target.value)} />
                                                    {errors.zip && <p className="errorMsg">{errors.zip}</p>}
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-12">


                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"></legend>
                                                <div className="row">
                                                    <div className="col-6">

                                                        <div className="mb-3 col-8">
                                                            <label className="form-label"><b>Company Status </b><i className="star">*</i></label>
                                                            <div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="status" id="inlineRadio1" value="Active" checked={status === "Active"} onChange={handleOptionChange} />
                                                                    <label className="form-check-label" for="inlineRadio1">Active</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" type="radio" name="status" id="inlineRadio2" value="Inactive" checked={status === "Inactive"} onChange={handleOptionChange} />
                                                                    <label className="form-check-label" for="inlineRadio2">In Active</label>
                                                                </div>
                                                            </div>
                                                            {errors.status && <p className="errorMsg">{errors.status}</p>}

                                                        </div>

                                                    </div>
                                                    <div className="col-6">
                                                        <div className="mb-3 col-8">
                                                            <label className="form-label"><b>Upload Logo</b></label>
                                                            <div className="d-flex mb-3">
                                                                <input type="file" className="form-control" placeholder="select banner image" name="selectedFile1"
                                                                    accept=".jpg, .jpeg"
                                                                    id={"img1"}
                                                                    //value={selectedFile}
                                                                    onChange={onSelectFile1} />

                                                                {/* <button className="btn btn-primary ms-3">Upload</button> */}

                                                            </div>

                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <div className="rounded col-md-4 text-left"  >
                                                                <img src={imagePreview} className="rounded w-100" alt="..." />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>

                                    </div>
                                    <br />
                                    <div className="form-group mt-4">
                                        <button type="button" className=" me-4 fw-medium  btn btn-primary btn-lg" onClick={onSubmitClick}>Create</button>
                                        <button type="button" className="me-4 fw-medium btn btn-primary btn-lg" onClick={() => { navigate("/companyList"); }}>Cancel</button>

                                    </div>


                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>


    );
};

export default CreateCompany;
