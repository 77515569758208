import axios from "axios";
import {useNavigate } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
let BASEURL = process.env.REACT_APP_API_URL;

axios.create({
    baseURL: BASEURL,
    headers: {
        "Content-Type": "application/json",
    },
});

axios.interceptors.request.use(
    async (config) => {
        if(config.method === 'put'){
            return config;
        }
        const accessToken = localStorage.getItem("token");

        if (accessToken) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${accessToken}`,
            };
        }

        return config;
    },
    (error) => Promise.reject(error)
);


axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log("Errrrrrr:",error.response.data.message);
        const config = error?.config;
        const message=error?.response.data.message;
        if (error?.response?.status === 401 && !config?.sent && message==="Refresh Token is Expired") {
              localStorage.clear();
              window.location.href="/";
        }
        else{
           // debugger;
           if(error?.response?.status === 401){
               config.sent = true;
               const refresh_token = localStorage.getItem("refresh-token");
               try {
                await axios.post("/refreshtoken", {
                       refreshToken: refresh_token,
                   }).then((res) => {
                       //debugger;
                       if (res?.data?.access?.token) {
                           //debugger;
                           localStorage.clear();
                           localStorage.setItem("token", res?.data?.access?.token);
                           localStorage.setItem("refresh-token", res?.data?.refresh?.token);
                           config.headers = {
                               ...config.headers,
                               authorization: `Bearer ${res?.data?.access?.token}`,
                             };
                             //return res;
                             return axios(config);
                       }
                   });        
               } catch (error) {
                   console.log("Errorrr:",error)
                   //debugger;
                   localStorage.clear();
                   const navigate = useNavigate();
                   navigate("/login"); 
               }
           }
            return Promise.reject(error);
        }
        return Promise.reject(error);
    }
);

export const AxiosPrivate = axios;