

import React, { useEffect } from "react";


const AppDownload = (props) => {

 

    useEffect(() => {
      let redirectUrl = "www.google.com";
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // checks whether the user agent iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
       redirectUrl = "www.google.com";
      }
      // redirect to the respective url
      window.location.replace(redirectUrl);
    }, []);



    return (
        <>
          <h1>AppDownload Page</h1>
        </>

    );
};

export default AppDownload;

