import React, { useState } from "react";
import { AxiosPrivate as axios } from "../axios/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ChangePassword = () => {
    let initialValue = {
        email: localStorage.getItem("email"),
        newPassword: "",
        confirmNewPassword:""
    };
    const [user, setUser] = useState(initialValue);
    const [visibility, setVisibility] = useState({
        newPassword: false,
        confirmNewPassword:false
      });
    const handlechange = (e) => {
        e.preventDefault();
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    const submitHandler = async (e) => {
        e.preventDefault();
        if (user.email.length <= 0 || user.newPassword <= 0 || user.confirmNewPassword <= 0) {
            if (user.email.length <= 0 || user.email.includes("@") === false || user.email.includes(".") === false) {
                toast.error("Please Enter Email Address", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (user.newPassword.length <= 0 ) { 
                toast.error("Please Enter New Password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            else{ 
                toast.error("Please Enter Confirm Password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else if(user.newPassword.length < 8 || !user.newPassword.match(/\d/) || !user.newPassword.match(/[a-zA-Z]/)){
            toast.error("Password must be at least 8 characters and must contain at least 1 letter and 1 number", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(user.newPassword !== user.confirmNewPassword){
            toast.error("New Password & Confirm Password do not match", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`, user).then((res) => {
                if (res.data.response.status === "success") {
                    toast.success(`${res.data.response.message}. Please login with new password`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setUser(initialValue);
                    setTimeout(() => {
                        localStorage.clear();
                        window.location.href = "/login";
                    }, 3000);
                } 
            }).catch((err) => {
                toast.error(err?.response?.data?.message || "Something went wrong", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    };
    return (
        <>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Change Password</h4>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="border rounded mt-4 p-5">
                            <p>Please enter the new password</p>
                            <div className="mb-3 col-6">
                                <label className="form-label"><b>Email</b> </label>
                                <p>{user.email}</p>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">New Password</label>
                                <input
                                    type={visibility.newPassword ? 'text': 'password'}
                                    name="newPassword"
                                    value={user.newPassword}
                                    onChange={handlechange}
                                    className="form-control"
                                />
                            <span className={`viewpass mr-4 text-muted ${visibility.newPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={(e) => {
                                e.preventDefault();
                                setVisibility({ ...visibility, newPassword: !visibility.newPassword });
                            }}></span>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Confirm Password</label>
                                <input
                                    type={visibility.confirmNewPassword ? 'text': 'password'}
                                    name="confirmNewPassword"
                                    value={user.confirmNewPassword}
                                    onChange={handlechange}
                                    className="form-control"
                                />
                                <span className={`viewpass mr-4 text-muted ${visibility.confirmNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`} onClick={(e) => {
                                e.preventDefault();
                                setVisibility({ ...visibility, confirmNewPassword: !visibility.confirmNewPassword });
                            }}></span>
                            </div>
                            <button className="btn btn-primary w-30 mt-4 mb-3" onClick={submitHandler}>
                                Submit
                            </button>
                        </div>

                    </div>
                </div>
            </div>


        </>


    );
};

export default ChangePassword;
