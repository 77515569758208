// import "./scss/bootstrap/bootstrap.scss";
import Routers from "./Routers";
import "./scss/bootstrap/bootstrap.scss";
import "./scss/style.scss";
function App() {
    return (
        <div className="App" style={{height: '100vh'}}>
            {/* <AxiosPrivate>  */}
            <Routers>

            </Routers>
            {/* </AxiosPrivate> */}
        </div>
    );
}

export default App;
