import { AxiosPrivate as axios } from "../axios/axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../images/URLane.png";

const Login = () => {
    let initialValue = {
        email: "",
        password: ""
    };
    const [user, setUser] = useState(initialValue);
    const handlechange = (e) => {
        e.preventDefault();
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    const userlogin = async (e) => {
        e.preventDefault();
        if (user.email.length <= 0 || user.password <= 0) {
            if (user.email.length <= 0 || user.email.includes("@") === false || user.email.includes(".") === false) {
                toast.error("Please Enter Email Address", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("Please Enter Password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            try {
                
                await axios.post(`${process.env.REACT_APP_API_URL}/login`, user).then((res) => {
                    if (res.data.message === "Logging in successfully") {
                        toast.success("Welcome", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setUser(initialValue);
                        localStorage.setItem("email",res?.data?.user?.email)
                        localStorage.setItem("token", res?.data?.tokens?.access?.token);
                        localStorage.setItem("refresh-token", res?.data?.tokens?.refresh?.token);
                        window.location.href = "/";
                    } else {
                        toast.error("Incorrect Email and Password", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                });
            } catch (err) {
                toast.error("Incorrect Username and Password", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };
    return (
        <div>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <div className="container-fluid position-relative w-100 ">
                <div className="row  justify-content-center overflow-hidden">
                    <div className="col-sm-4 align-self-center d-flex flex-column">
                        <img src={Logo} style={{}} className="mx-auto img-fluid mt-4 w-25" alt=""/>
                        <div className="border rounded mt-4 p-5">
                            <h3 className="text align-center">Login</h3>
                            {/* <p>Please enter the username and password</p> */}
                            <div className="mb-3 mt-4">
                                <label className="form-label">Email</label>
                                <input type="text" name="email" onChange={handlechange} className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            userlogin(event);
                                        }
                                    }}
                                    onChange={handlechange}
                                    className="form-control"
                                />
                            </div>
                            <button className="btn btn-primary w-100 mt-4 mb-3" onClick={userlogin}>
                                Login
                            </button>
                            {/* <Link to="/forgot-password" className="d-flex justify-content-center">
                                Forgot Password?
                            </Link> */}
                        </div>
                        <p className="small mt-3 text-center">© 2022 URLane, Inc. All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
