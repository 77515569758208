import React from "react";
//import { FaList } from "react-icons/fa";
import { FiHome, FiImage, FiUsers,FiActivity,FiHardDrive } from "react-icons/fi";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import Logo from "../../images/URLane.png";


const SideNav = () => {


    return (
        <div class="col-sm-2 px-0 h-100">
            <div class="w-100 h-100 p-3 border-end bg-light bg-opacity-10 d-flex flex-column">
                <img src={Logo} className="w-50 mb-3 mt-3 align-self-center" alt="" />
                <p class="small text-muted text-uppercase mb-0 mt-6">Main Menu</p>
                <nav class="d-flex my-3 flex-column">
                <Link to="/Dashboard" class="my-2 d-flex text-black-100">
                        <i class="nav-icon align-self-center"><FiActivity/></i>
                        <span class="align-self-center">Dashboard</span>
                    
                    </Link>
                    
                    <Link to="/BannerList" class="my-2 d-flex text-black-100">
                            <i class="nav-icon align-self-center"><FiImage/></i>
                            <span class="align-self-center">Banners</span>
                        
                    </Link>
                    <Link to="/CompanyList" class="my-2 d-flex text-black-100">
                        <i class="nav-icon align-self-center"><FiHome></FiHome></i>
                        <span class="align-self-center">Companies</span>
                    </Link>
                    <Link to="/usersList" class="my-2 d-flex text-black-100">
                        <i class="nav-icon align-self-center"><FiUsers/></i>
                        <span class="align-self-center">Users</span>
                    
                    </Link>
                    <Link to="/RouteList" class="my-2 d-flex text-black-100">
                        <i class="nav-icon align-self-center"><FiHardDrive/></i>
                        <span class="align-self-center">Routes</span>
                    
                    </Link>
                   
                </nav>
                <nav class="d-flex mt-auto flex-column">
                        <i class="nav-icon align-self-center me-2"></i>
                        <span class="align-self-center"></span>
                    
                </nav>
            </div>
        </div>


    );
};

export default SideNav;
