import React, { useEffect, useState } from "react";
import { Button,Table,Col,Row} from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { AxiosPrivate as axios } from "../axios/axios";
import { Link, useNavigate,useParams } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import moment from "moment";


const UserRoutes = (props) => {

    const navigate = useNavigate();
    let params = useParams();
    const [routeList, setRouteList] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [page, setPage] = useState(1);
    const [sortType, setSortType] = useState("desc");
    const [sortBy, setSortBy] = useState("route_id");
    const sizePerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [routeName,setRouteName]=useState("");
    const [routeStatus,setRouteStatus]=useState("");
    const [remountPagination,setRemountPagination]=useState(0);
    const [isLoading,setIsLoading]=useState(false);

    const linkFormatter = (id) => {
        navigate(`/view-route-details/${id}`);
    };

    useEffect(() => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/userroutedetails?user_id=${params.id}&route_status=&name=&size=10&sortBy=route_id&sortType=desc&page=1`;
        axios.get(url).then((res) => {
            if (res?.data?.response?.status === "success") {
                setIsLoading(false);
                setUserDetails(res?.data?.response?.user);
                setPageCount(Math.ceil(res?.data?.response?.userRoutedetails?.totalResults / sizePerPage))
                setRouteList(res?.data?.response?.userRoutedetails?.results);
            }
        });

    }, [params.id]);

    
    const getUsersRoute= (routeName, routeStatus, currentPage, sizePerPage, sortField, sortOrder) => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/userroutedetails?user_id=` + params.id +
            `&route_status=` + routeStatus +
            `&name=` + routeName +
            `&page=` + currentPage +
            `&size=` + sizePerPage +
            `&sortBy=` + sortField +
            `&sortType=` + sortOrder + ``;
        axios.get(url).then((res) => {
            setIsLoading(false);
            if (res?.data?.response?.status === "success") {
                setIsLoading(false);
                setUserDetails(res?.data?.response?.user);
                setPageCount(Math.ceil(res?.data?.response?.userRoutedetails?.totalResults / sizePerPage))
                setRouteList(res?.data?.response?.userRoutedetails?.results);
            }
            

        });
        setSortType(sortOrder);
        setSortBy(sortField);
        setPage(currentPage);
    };



    const onSearchClear = () => {
        setRouteName("");
        setRouteStatus("");
        setPage(1);

        setRemountPagination(Math.random);
        getUsersRoute("","",1,sizePerPage,"route_id","Asc");
    };


    const submitSearch = () => {
        setPage(1);

        setRemountPagination(Math.random);
        getUsersRoute(routeName,routeStatus,1,sizePerPage,"route_id","Asc");
    };

    const handlePageClick = (event) => {
        let currentPage = event.selected + 1;
        setPage(currentPage);
        getUsersRoute(routeName,routeStatus,currentPage,sizePerPage,sortBy,sortType);
    };


    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom">
                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Users</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item"><Link to="/usersList">Users</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Users Routes</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="row align-items-start">
                                <div className="col-12">
                                    <h5 className="mb-0 text-black fw-semibold ">Users Route Lists - {userDetails.first_name + " "+ userDetails.last_name}</h5>
                                </div>
                                
                            </div>
                            <br />
                            <br />
                            <div className="row">

                                <Row>
                                    <Col>
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            Status
                                        </label>
                                        <div className="input-group w-auto">
                                            <select
                                            value={routeStatus|| ""}
                                                onChange={(e) => setRouteStatus(e.target.value)}
                                                className="form-select form-select-sm text-black w-auto border-gray fs-6"
                                                aria-label=".form-select-sm example"
                                            >
                                                <option value="">
                                                    All status
                                                </option>
                                                <option value="Not Started">Not Started</option>
                                                <option value="Started">Started</option>
                                                <option value="Completed">Completed</option>
                                            </select>
                                        </div>
                                    </Col>

                                    <Col>
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            Route Name
                                        </label>
                                        <div className="input-group w-auto">
                                            <input type="text" name="routeName" value={routeName}
                                                className="form-control"
                                                onChange={(e) => setRouteName(e.target.value)}></input>

                                        </div>
                                    </Col>

                                   
                                    <Col className="mt-4">
                                        <Button variant="primary" className="btn btn-dark me-4 fs-6 fw-medium rounded-pill w-130 " size="lg" type="button" onClick={submitSearch} >
                                            Search
                                        </Button>
                                        <Button variant="primary" className="btn btn-dark me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button" onClick={onSearchClear} >
                                            Clear
                                        </Button>
                                    </Col>
                                </Row>


</div>
                            <div className="row">
                                <div className="row align-items-start">
                                    <div className="p-3">
                                        <div className="col-12">
                                            <Table responsive className="table table-responsive"   hover size="sm" >
                                                <thead className="bg-dark text-muted text-nowrap">
                                                    <tr >
                                                    <th style={{color:" #ffff", fontWeight:"bold", paddingBottom:"5px"}} variant="default">S.No</th>
                                                        <th className="p-0" scope="col">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersRoute(routeName,routeStatus,page,sizePerPage,"route_id",sortType === "desc" ? "asc" : "desc");
                                                                  
                                                                }}
                                                            >
                                                                Route Id
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0">
                                                            <Button data-bs-toggle="modal"
                                                                role="button"
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersRoute(routeName,routeStatus,page,sizePerPage,"route_name",sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                              Route Name
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button></th>
                                                        <th className="p-0">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersRoute(routeName,routeStatus,page,sizePerPage,"distance",sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                               Distance
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                       
                                                        <th className="p-0">
                                                        <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersRoute(routeName,routeStatus,page,sizePerPage,"route_status",sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                               Status
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersRoute(routeName,routeStatus,page,sizePerPage,"start_date",sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                               Start Date
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                               
                                                            >
                                                               Start Time
                                                               
                                                            </Button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {isLoading?
                                                <tbody>
                                                        <ThreeDots 
                                                        height="80" 
                                                        width="80" 
                                                        radius="9"
                                                        color="#0275d8" 
                                                        ariaLabel="Loading Copmanies list"
                                                        wrapperStyle={{ position: "fixed"  ,left: "50%"} }
                                                        wrapperClassName=""
                                                        visible={true}
                                                         />
                                                </tbody>:
                                                routeList && routeList.length > 0 ? (
                                                    <tbody class="text-black-50">
                                                        {routeList.map((info,index) => {
                                                            return (
                                                                <tr style={{ cursor: "pointer" }} className="table-light">
                                                                    <td >{index + 1}</td>
                                                                    <td onClick={() => linkFormatter(info?.route_id)} >{info.route_id}</td>
                                                                    <td onClick={() => linkFormatter(info?.route_id)}>{info.route_name}</td>
                                                                    <td onClick={() => linkFormatter(info?.route_id)}>{ info.distance ? info.distance : "-"}{info.distance === " " && "-"}</td>
                                                                    
                                                                    <td  onClick={() => linkFormatter(info?.route_id)}>{info.route_status}</td>
                                                                    <td onClick={() => linkFormatter(info?.route_id)}>{info.start_date?moment(info.start_date).format("MM/DD/YYYY"):"N/A"}</td>
                                                                    <td onClick={() => linkFormatter(info?.route_id)}>{info.start_time?moment(info.start_time, "HH:mm:ss").format("hh:mm A"):"N/A"}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                ) : (
                                                    
                                                    <tbody >
                                                        <tr>
                                                            <td colspan="6" align="center"><div className="col-12">No Data found</div></td>
                                                        </tr>
                                                        
                                                    </tbody>
                                                    
                                                )}
                                            </Table>
                                        </div>
                                       
                                        <div className="p-3 col-12" key={remountPagination}>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={1}
                                                //marginPagesDisplayed={2}
                                                pageCount={pageCount}
                                                previousLabel="< previous"
                                                renderOnZeroPageCount={null}
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"

                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>

    );
};

export default UserRoutes;
