import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Table } from "react-bootstrap";
import { FiEdit, FiSearch } from "react-icons/fi";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import { ThreeDots } from 'react-loader-spinner';

import { AxiosPrivate as axios } from "../components/axios/axios";

import moment from "moment";
import { Link, useNavigate } from "react-router-dom";


const BannerList = (props) => {

    const navigate = useNavigate();
    const [status, setStatus] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [dataList, setDataList] = useState([]);
    const [page, setPage] = useState("1");
    const [sortType, setSortType] = useState("desc");
    const [sortBy, setSortBy] = useState("banner_id");
    const [title, setTitle] = useState("");
   // const [totalResults, setTotalResults] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading,setIsLoading]=useState(false);
    const sizePerPage = 10;  
    const [remountPagination,setRemountPagination]=useState(0);

    const linkFormatter = (id) => {
        navigate(`/view-banner/${id}`);
    };

    const getListData = async (sortField, sortOrder, currentPage,title,status,startDate,endDate) => {
        setIsLoading(true);
        let s_date = "", e_date = "";
        if (startDate)
            s_date = moment(startDate).format("MM/DD/YYYY HH:mm:ss");

        if (endDate)
            e_date = moment(endDate).format("MM/DD/YYYY HH:mm:ss");
        const url = `${process.env.REACT_APP_API_URL}/banner/list?title=` + title +
            `&status=` + status +
            `&start_date=` + s_date +
            `&end_date=` + e_date +
            `&page=` + currentPage +
            `&size=` + sizePerPage +
            `&sortBy=` + sortField +
            `&sortType=` + sortOrder + ``;
        await axios.get(url).then((res) => {
            if (res?.data?.response?.status === "success") {
                setIsLoading(false);
                setDataList(res?.data?.response?.banners?.results);
               // setTotalResults(res.data.response.banners.totalResults);
                setPageCount(Math.ceil(res?.data?.response?.banners?.totalResults / sizePerPage));
            }

        });

        setSortType(sortOrder);
        setSortBy(sortField);
        setPage(currentPage);
    }

    useEffect(() => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/banner/list?title=&status=&start_date=&end_date=&page=1&size=10&sortBy=is_active&sortType=desc`;
        axios.get(url).then((res) => {
            if (res?.data?.response?.status === "success") {
                setIsLoading(false);
                setDataList(res?.data?.response?.banners?.results);
               // setTotalResults(res.data.response.banners.totalResults);
                setPageCount(Math.ceil(res?.data?.response?.banners?.totalResults / sizePerPage));
            }
        });

    }, []);

    useEffect(() => {
        setIsLoading(true);
        if(status!=="")
        getListData(sortBy, sortType, page,title,status,startDate,endDate);
        if(status=="")
        getListData(sortBy, sortType, page,title,status,startDate,endDate);
    }, [status]);

    const search = (e) => {
        setTitle(e.target.value.toLowerCase());
        getListData(sortBy, sortType, page,e.target.value.toLowerCase(),status,startDate,endDate);
    };

    const filter = (val) => {
        setRemountPagination(Math.random);
        getListData(sortBy, sortType, page,title,status,startDate,endDate);
    };
    const onSearchClear = () => {
        setRemountPagination(Math.random);
        setPage(1);
        setStatus("");
        setStartDate("");
        setEndDate("");
        getListData("is_active","desc", 1,"","","","");
    };
    const handlePageClick = (event) => {
        let currentPage = event.selected + 1;
        setPage(currentPage);
        getListData(sortBy, sortType, currentPage,title,status,startDate,endDate);
    };

    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom col-12">
                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Banners</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">Banners</li>
                                <li className="breadcrumb-item active" aria-current="page">List</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="row align-items-start">
                                <div className="col-9">
                                    <h5 className="mb-0 text-black fw-semibold ">Banner List</h5>
                                </div>
                                <div className="col">
                                    <InputGroup className="input-group w-auto">
                                        <InputGroup.Text className=" border-end-0 border" style={{ borderRadius: "15px 0px 0px 15px" }}>
                                            <button style={{ borderStyle: "none", backgroundColor: "transparent", color: "black" }} >
                                                <FiSearch />
                                            </button>
                                        </InputGroup.Text>

                                        <FormControl
                                            className="form-control text-black border-start-0 border  search-right-br"
                                            type="text"
                                            placeholder="Search by title..."
                                            onChange={search}
                                            style={{ borderRadius: "0px 15px 15px 0px" }}
                                        ></FormControl>
                                    </InputGroup>
                                </div>

                            </div>
                            <br />
                            <div className="row align-items-start col-12">
                                <div className="col-2">
                                    <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center col-2">
                                        Status
                                    </label>
                                    <div className="input-group w-auto">
                                        <select
                                            defaultValue={""}
                                            value={status}
                                            selected={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            className="form-select form-select-sm text-black w-auto border-gray fs-6"
                                            aria-label=".form-select-sm example"
                                        >
                                            <option value="">
                                                All status
                                            </option>
                                            <option value="Active">Active</option>
                                            <option value="Inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-3">


                                    <div className="input-group w-auto">
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2  align-self-center">
                                            From
                                        </label>
                                        <DatePicker
                                            className="form-control picker"
                                            selected={startDate}
                                            placeholderText={"MM/DD/YYYY"}
                                            onChange={(date) => setStartDate(date)}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="input-group w-auto ">
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            To
                                        </label>
                                        <DatePicker
                                            className="form-control picker"

                                            selected={endDate}
                                            placeholderText={"MM/DD/YYYY"}
                                            onChange={(date) => setEndDate(date)}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="mt-4 col-4">
                                    <Button variant="primary" className="me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button"
                                     onClick={filter}
                                    >
                                        Search
                                    </Button>

                                    <Button variant="primary" className="me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button" onClick={onSearchClear} >
                                            Clear
                                        </Button>
                                    <Link to={`/create-banner`} >
                                        <Button variant="primary" className="me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button">
                                            Create
                                        </Button>
                                    </Link>
                                </div>



                            </div>

                            <div className="row align-items-start">
                                <div className="p-3">
                                    <div className="col-12">
                                    <Table responsive className="table table-responsive"   hover size="sm" >
                                                <thead className="bg-dark text-muted text-nowrap">
                                                <tr >
                                                <th style={{color:" #ffff", fontWeight:"bold", paddingBottom:"5px"}} variant="default">S.No</th>
                                                    <th className="p-0" scope="col">
                                                        <Button
                                                            variant="default"
                                                            type="button"
                                                            className="btn btn-dark"
                                                            onClick={() => {
                                                                getListData("banner_id", sortType === "desc" ? "asc" : "desc", page,title,status,startDate,endDate);
                                                            }}
                                                        >
                                                            ID
                                                            <span className="fs-6 m-2">
                                                                <i
                                                                    className={`${sortType === "desc"
                                                                        ? "fa fa-fw fa-sort-desc"
                                                                        : "fa fa-fw fa-sort-asc"
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Button>
                                                    </th>
                                                    <th className="p-0">
                                                        <Button data-bs-toggle="modal"
                                                         role="button"
                                                            variant="default"
                                                            type="button"
                                                            className="btn btn-dark"
                                                            onClick={() => {
                                                                getListData("title", sortType === "desc" ? "asc" : "desc", page,title,status,startDate,endDate);
                                                            }}
                                                        >
                                                            Title
                                                            <span className="fs-6 m-2">
                                                                <i
                                                                    className={`${sortType === "desc"
                                                                        ? "fa fa-fw fa-sort-desc"
                                                                        : "fa fa-fw fa-sort-asc"
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Button></th>
                                                    <th className="p-0">
                                                        <Button
                                                            variant="default"
                                                            type="button"
                                                            className="btn btn-dark"
                                                            onClick={() => {
                                                                getListData("start_Date", sortType === "desc" ? "asc" : "desc", page,title,status,startDate,endDate);
                                                            }}
                                                        >
                                                            Start Date
                                                            <span className="fs-6 m-2">
                                                                <i
                                                                    className={`${sortType === "desc"
                                                                        ? "fa fa-fw fa-sort-desc"
                                                                        : "fa fa-fw fa-sort-asc"
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Button>
                                                    </th>
                                                    <th className="p-0">
                                                        <Button
                                                            variant="default"
                                                            type="button"
                                                            className="btn btn-dark"
                                                            onClick={() => {
                                                                getListData("end_Date", sortType === "desc" ? "asc" : "desc", page,title,status,startDate,endDate);
                                                            }}
                                                        >
                                                            End Date
                                                            <span className="fs-6 m-2">
                                                                <i
                                                                    className={`${sortType === "desc"
                                                                        ? "fa fa-fw fa-sort-desc"
                                                                        : "fa fa-fw fa-sort-asc"
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Button>
                                                    </th>

                                                    <th className="p-0">
                                                        <Button
                                                            variant="default"
                                                            type="button"
                                                            className="btn btn-dark"
                                                            onClick={() => {
                                                                getListData("is_active", sortType === "desc" ? "asc" : "desc", page,title,status,startDate,endDate);
                                                            }}
                                                        >
                                                            Status
                                                            <span className="fs-6 m-2">
                                                                <i
                                                                    className={`${sortType === "desc"
                                                                        ? "fa fa-fw fa-sort-desc"
                                                                        : "fa fa-fw fa-sort-asc"
                                                                        }`}
                                                                ></i>
                                                            </span>
                                                        </Button>
                                                    </th>
                                                    {/* <th scopa="col"   style={{color:" #ffff", fontWeight:"bold", paddingBottom:"5px"}} variant="default">Status {sortType === "desc" ? <i class="fa fa-fw fa-sort-desc"></i> : <i class="fa-sort-asc"></i>}</th> */}
                                                    <th scopa="col"   style={{color:" #ffff", fontWeight:"bold", paddingBottom:"5px"}} variant="default">Edit</th>
                                                </tr>
                                            </thead>
                                            {isLoading?
                                                <tbody>
                                                        <ThreeDots 
                                                        height="80" 
                                                        width="80" 
                                                        radius="8"
                                                        align="center"
                                                        color="#0275d8" 
                                                        ariaLabel="Loading Copmanies list"
                                                        wrapperStyle={{ position: "fixed"  ,left: "50%"} }
                                                        wrapperClassName=""
                                                        visible={true}
                                                         />
                                               
                                                </tbody>:
                                            dataList && dataList.length > 0 ? (
                                                <tbody class="text-black-50">
                                                    {dataList.map((info,index) => {
                                                        return (
                                                            <tr style={{ cursor: "pointer" }} className="table-light">
                                                                <td >{(page - 1) * 10 + (index + 1)}</td>
                                                                <td onClick={() => linkFormatter(info?.banner_id)}>{info.banner_id}</td>
                                                                <td onClick={() => linkFormatter(info?.banner_id)}>{info.title}</td>
                                                                <td onClick={() => linkFormatter(info?.banner_id)}>{info?.start_Date?moment(info.start_Date).format("MM/DD/YYYY hh:mm"):"N/A"}</td>
                                                                <td onClick={() => linkFormatter(info?.banner_id)}>{info.end_Date?moment(info.end_Date).format("MM/DD/YYYY hh:mm"):"N/A"}</td>
                                                                <td onClick={() => linkFormatter(info?.banner_id)} className={info.is_active === true ? "text-success" : "text-warning"}>
                                                                    {info.is_active ? "Active" : "Inactive"}
                                                                </td>
                                                                <td onClick={() =>navigate(`/edit-banner/${info?.banner_id}`) }>{<FiEdit />}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            ) : (
                                                <tbody >
                                                <tr>
                                                    <td colspan="6" align="center"><div className="col-12">No Data found</div></td>
                                                </tr>
                                                
                                            </tbody>
                                            )}
                                            
                                        </Table>
                                        </div>
                                         
                                        <div className="p-3 col-12" key={remountPagination}>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="next >"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={1}
                                            //marginPagesDisplayed={2}
                                            pageCount={pageCount}
                                            previousLabel="< previous"
                                            renderOnZeroPageCount={null}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    );
};

export default BannerList;
