import React from "react";
import { Link } from "react-router-dom";
//import { FaInfinity } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
const Header = () => {
    return (

        <div class="w-100 h-100 d-flex flex-column pb-3">
            <div class="container-fluid py-4">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
                <div class="row">
                   

                    <div class="col-auto ms-auto align-self-center dropdown">
                        <div class="bg-light rounded-pill d-flex py-2 px-3 cursor-pointer" id="profile-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                           
                            <div class="side-logo text-center pb-1">
                                {/* <FaInfinity /> */}
                            </div>
                            <span></span>
                            <p class="mb-0">Super Admin</p>
                        </div>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profile-dropdown">
                        <li>
                            <Link to={`/change-password`} style={{ color: "black" }} className="d-flex justify-content-center">
                                Change Password
                            </Link>
                        </li>
                            <li><span
                                className="dropdown-item"
                                onClick={() => {
                                    localStorage.removeItem("token");
                                    window.location.href = "/login";
                                }}
                                style={{ cursor: "pointer" }}>
                                Logout
                                </span>{" "}</li>
                            
                        </ul>
                    </div>
                </div>
            </div>

        </div>


    );
};
export default Header;
