import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import validator from 'validator';
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { AxiosPrivate as axios } from "../components/axios/axios";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CreateBanner = (props) => {
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedFile1, setSelectedFile1] = useState([]);
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState("Inactive");
    const [bannerType, setBanneType] = useState("Header");
    const [url, setUrl] = useState("");
    const [errors, setErrors] = useState({});
    const [imagePreview, setImagePreview] = useState("https://dummyimage.com/100x100/ddd/000");
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [notificationError, setNotificationError] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [bannerCost,setBannerCost]=useState("");
    const [agreementPdf, setAgreementPdf] = useState("");
    const [awsSuccess, setAwsSuccess] = useState("");
    const [pdfFilename,setPdfFilename]=useState("");
    const [imageFilename,setImageFilename]=useState("");

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/banner/getallcompanyid`)
            .then((res) => {
                if (res?.data?.response?.status === "success") {
                    setCompanies(res?.data?.response?.company);
                }

            });
    }, []);


    const onSelectFile1 = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile1(undefined);            
        }
        else {
            
            let img= Date.now() + e.target.files[0].name;
            setImageFilename(img);
            setSelectedFile1(e.target.files[0]);
            setImagePreview(URL.createObjectURL(e.target.files[0])); 
            setAwsSuccess(false); 
        }
    };

    const onSelectPdf = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setAgreementPdf(undefined);

        }
        else {
            let pdf=Date.now() + e.target.files[0].name;
            setPdfFilename(pdf);
            setAgreementPdf(e.target.files[0]);  
            setAwsSuccess(false);      
        }
    };

    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    const handleOptionChange = (e) => {
        setStatus(e.target.value);
    };

    const handleBannerTypeChange = (e) => {
        setBanneType(e.target.value);
    };

    const validateForm = () => {
        let isFormValid = true;
        const formErrors = {};
        var today = new Date();
        var current_date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
        if (!title) {
            formErrors.title = "Please enter banner title";
            isFormValid = false;
        }
        if (!startDate) {
            formErrors.startDate = "Please select start date";
            isFormValid = false;
        }
        else {

            if (startDate < current_date) {
                formErrors.startDate = "Start date should be before today";
                isFormValid = false;
            }

        }

        if (!companyId) {
            formErrors.companyId = "Please select Company name";
            isFormValid = false;
        }

        if (!endDate) 
        {
            formErrors.endDate = "Please select end date";
            isFormValid = false;
        }
        else{
            if (!validator.isDate(endDate)) {
                formErrors.endDate = "Please select valid end date";
                isFormValid = false;
            }
            else {
                if (endDate < startDate) {
                    formErrors.endDate = "End date should be greater than start date";
                    isFormValid = false;
                }
            }
        }
        if (url && !validator.isURL(url)) {
            formErrors.url = "Please enter valid URL";
            isFormValid = false;
        }

        if (!bannerCost) 
        {
            formErrors.bannerCost = "Please enter banner cost";
            isFormValid = false;
        }

        if (selectedFile1.length <= 0) {
            formErrors.selectedFile1 = "Please upload image";
            isFormValid = false;
        }

        if (agreementPdf.length <= 0) {
            formErrors.agreementPdf = "Please upload agreement";
            isFormValid = false;
        }
        setErrors(formErrors);
        return isFormValid;
    };

    const uploadFilesInAWS = async (imgFileName, image, pdfFileName, pdfFile) => {

        let endpointsUrl = [
            `${process.env.REACT_APP_API_S3_URL}/v1/s3/put-presigned-url?fileName=${pdfFileName}`,
            `${process.env.REACT_APP_API_S3_URL}/v1/s3/put-presigned-url?fileName=${imgFileName}`
        ];
       var awsImageUrl="",awsPdfUrl="";

        const getAWSUrl = await Promise.all(endpointsUrl.map((endpoint) => axios.get(endpoint)))
            .then(([{ data: pdfUrl }, { data: imgUrl }]) => {
                awsPdfUrl=pdfUrl.url;
                awsImageUrl=imgUrl.url;
                return true;

            }).catch(error => {
                return false;
            });

        if (getAWSUrl) {
            if (awsImageUrl !== "" && awsPdfUrl !== "") {
                await axios({
                    method: "put",
                    url: awsImageUrl,
                    data: image,
                }).then(res => setAwsSuccess(true)).catch(error => { setAwsSuccess(false) });

                await axios({
                    method: "put",
                    url: awsPdfUrl,
                    data: pdfFile,
                }).then(res => setAwsSuccess(true)).catch(error => { setAwsSuccess(false) });

            }
        }
        return awsSuccess;
    }




    const onSubmitClick = async (e) => {
        e.preventDefault();


        if (validateForm()) {
            // eslint-disable-next-line no-unused-vars
            let awsStatus="";
            if(!awsSuccess)
                awsStatus = await uploadFilesInAWS(imageFilename, selectedFile1, pdfFilename, agreementPdf);
            else
            // eslint-disable-next-line no-unused-vars
              awsStatus=true;
              
        
        // if (awsStatus) {
            let formData = {
                Title: title,
                BannerType: bannerType,
                BannerImagePath: imageFilename,
                StartDate: startDate,
                EndDate: endDate,
                BannerURL: url,
                Status: status,
                CompanyId:companyId,
                BannerCost:bannerCost,
                PdfName: pdfFilename,

            };
            axios.post(`${process.env.REACT_APP_API_URL}/banner/create`, formData).then((res) => {
                let status = res?.data?.response?.status;

                if (status === "success") {
                    toast.success(res?.data?.response?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });


                    navigate("/BannerList");
                }
                else {
                    window.scrollTo(0, 0);
                    setShowErrorNotification(true);
                    setNotificationError(res?.data?.response?.message);
                }
            });

        // }
        // else {
        //     setShowErrorNotification(true);
        //     setNotificationError("Something Went Wrong!Please contact Admin")
        // }

    }
}




    return (
        <>

            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Banner</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/BannerList">Banner</Link>

                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Create Banner</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="p-3">

                                <h5 className="mb-0">Create Banner</h5>

                            </div>
                            {showErrorNotification ?
                                <div className="alert alert-danger col-4" role="alert">
                                    {notificationError}
                                </div> : null}
                            <div className="p-3">
                                <form action="">
                                    <fieldset className="border rounded-3 p-3">
                                        <legend className="float-none w-auto px-3"><h5>Banner Details</h5></legend>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Banner Title</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="banner_title" placeholder="Enter banner title" value={title} onChange={handleChange} />
                                                    {errors.title && <p className="errorMsg">{errors.title}</p>}
                                                </div>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>URL</b></label>
                                                    <input type="text" className="form-control" name="url"
                                                        placeholder="Enter URL "
                                                        value={url}
                                                        onChange={handleUrlChange} />
                                                    {errors.url && <p className="errorMsg">{errors.url}</p>}
                                                </div>
                                                <div className="mb-3 col-4">
                                                    <label className="form-label"><b>Banner Type </b><i className="star">*</i></label>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Bannertype" id="inlineRadio1" value="Header" checked={bannerType === "Header"} onChange={handleBannerTypeChange} />
                                                            <label className="form-check-label" for="inlineRadio1">Header</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Bannertype" id="inlineRadio2" value="Footer" checked={bannerType === "Footer"} onChange={handleBannerTypeChange} />
                                                            <label className="form-check-label" for="inlineRadio2">Footer</label>
                                                        </div>
                                                    </div>
                                                    {errors.bannerType && <p className="errorMsg">{errors.bannerType}</p>}

                                                </div>
                                                <div className="mb-3 col-4">
                                                    <br></br>
                                                    <label className="form-label"><b>Banner Status </b><i className="star">*</i></label>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="status" id="inlineRadio1" value="Active" checked={status === "Active"} onChange={handleOptionChange} />
                                                            <label className="form-check-label" for="inlineRadio1">Active</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="status" id="inlineRadio2" value="Inactive" checked={status === "Inactive"} onChange={handleOptionChange} />
                                                            <label className="form-check-label" for="inlineRadio2">In Active</label>
                                                        </div>
                                                    </div>
                                                    {errors.status && <p className="errorMsg">{errors.status}</p>}

                                                </div>


                                            </div>
                                            <div className="col-6">
                                                <div className="mb-3 col-8">
                                                    <label className="form-label font-weight-bold"><b>Start Date </b><i className="star">*</i></label>

                                                    <DatePicker
                                                        className="form-control"
                                                        selected={startDate}
                                                        placeholderText={"MM/DD/YYYY"}
                                                        onChange={(date) => setStartDate(date)}
                                                        showTimeSelect
                                                        minDate={new Date()}
                                                        //maxDate={addMonths(new Date(), 5)}
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="MM/dd/yyyy  HH:mm:ss"
                                                    />
                                                    {errors.startDate && <p className="errorMsg">{errors.startDate}</p>}
                                                </div>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>End Date</b><i className="star">*</i></label>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                        minDate={new Date()}
                                                        placeholderText={"MM/DD/YYYY"}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="MM/dd/yyyy HH:mm:ss"
                                                    />
                                                    {errors.endDate && <p className="errorMsg">{errors.endDate}</p>}

                                                </div>


                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Upload Image</b> <i className="star">*</i></label>
                                                    <div className="d-flex mb-3">
                                                        <input type="file" className="form-control" placeholder="select banner image" name="selectedFile1"
                                                            accept=".jpg, .jpeg"
                                                            id={"img1"}
                                                            //value={selectedFile}
                                                            onChange={onSelectFile1} />

                                                        {/* <button className="btn btn-primary ms-3">Upload</button> */}

                                                    </div>
                                                    <div>{errors.selectedFile1 && <p className="errorMsg">{errors.selectedFile1}</p>}</div>


                                                </div>
                                                <div className="mb-3 col-4">
                                                    <div className="rounded col-md-4 text-left"  >
                                                        <img src={imagePreview} className="rounded w-100" alt="..." />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <br></br>
                                    <fieldset className="border rounded-3 p-3">
                                        <legend className="float-none w-auto px-3"><h5>Company Details</h5></legend>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Cost</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="banner_cost" placeholder="Enter cost" value={bannerCost} onChange={(e) => setBannerCost(e.target.value)} />
                                                    {errors.bannerCost && <p className="errorMsg">{errors.bannerCost}</p>}
                                                </div>



                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Company Name</b><i className="star">*</i></label>
                                                    <div>
                                                        <select className="form-control form-select form-select-sm  text-black-50 fs-6 me-2 align-self-center col-2 " value={companyId} onChange={(e) => setCompanyId(e.target.value)}>
                                                            <option value="0">Select Company</option>
                                                            {companies.map((item) =>
                                                                <option value={item.company_id}>
                                                                    {item.company_name}
                                                                </option>
                                                            )
                                                            }
                                                        </select>
                                                    </div>
                                                    {errors.companyId && <p className="errorMsg">{errors.companyId}</p>}

                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Upload  Agreement</b> <i className="star">*</i></label>
                                                    <div className="d-flex mb-3">
                                                        <input type="file" className="form-control" placeholder="select agreement file" name="agreementPdf"
                                                            accept=".pdf"
                                                            id={"agreementPdf"}
                                                            onChange={onSelectPdf} />

                                                    </div>
                                                     <div>{errors.agreementPdf && <p className="errorMsg">{errors.agreementPdf}</p>}</div> 


                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <hr />
                                    <div className="form-group">
                                        <button className="me-4 fw-medium  btn btn-primary btn-lg" type="button" onClick={onSubmitClick}>Create</button>
                                        <button className="me-4 fw-medium  btn btn-primary btn-lg" type="button" onClick={() => { navigate("/BannerList"); }}>Cancel</button>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>


    );
};

export default CreateBanner;
