import React, { useEffect, useState } from "react";
import "./style.css";
import { useParams, Link } from "react-router-dom";
import { Table} from "react-bootstrap";
import { AxiosPrivate as axios } from "../axios/axios";
import "react-toastify/dist/ReactToastify.css";

import { ThreeDots } from 'react-loader-spinner';
import moment from "moment";



const ViewRoutes = (props) => {
    //const navigate = useNavigate();
    let params = useParams();
    const [routeDetails, setRouteDetails] = useState({});
    const [stopList, setStopList] = useState([]);
    const [isLoading,setIsLoading]=useState(false);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/routedetails`, {
                params: {
                    route_id: `${params.id}`,
                },
            })
            .then((res) => {
                setIsLoading(false);
                setRouteDetails(res?.data?.response?.route_details);
                setStopList(res?.data?.response?.route_details?.route_stops);
            });
    }, [params.id]);

  
    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Routes</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/RouteList">Routes</Link>

                                </li>
                                <li className="breadcrumb-item active" aria-current="page">View Route Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="p-3">
                                <form action="">
                                    <div className="row">
                                        <div className="col-12">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>Route Details</h5></legend>
                                                <div className="row">

                                                    <div className="col-6">
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Route Id</b> </label>
                                                            <p >{routeDetails.route_id}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Route Status</b> </label>
                                                            <p >{routeDetails.route_status}</p>
                                                        </div>
                                                     

                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Start Latitude</b> </label>

                                                            <p style={{ marginLeft: routeDetails.start_latitude === null || routeDetails.start_latitude === "" ? "25px" : "0" }}>{routeDetails.start_latitude?routeDetails.start_latitude:"-"}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Start Longitude</b> </label>

                                                            <p style={{ marginLeft: routeDetails.start_longitude === null || routeDetails.start_longitude === "" ? "25px" : "0" }}>{routeDetails.start_longitude?routeDetails.start_longitude:"-"}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                             <label className="form-label"><b>Start Address</b> </label>

                                                            <p style={{ marginLeft: routeDetails.start_address === null || routeDetails.start_address === ""? "25px" : "0" }}>{routeDetails.start_address?routeDetails.start_address:"-"}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                             <label className="form-label"><b>Start Date</b> </label>

                                                            <p style={{ marginLeft: routeDetails.start_date === null || routeDetails.start_date === "" ? "25px" : "0" }}>{routeDetails?.start_date?moment(routeDetails?.start_date).format('MM/DD/YYYY'):"N/A"}</p>
                                                        </div>
                    


                                                    </div>

                                                    <div className="col-6">
                                                    <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Route Name</b></label>
                                                            <p >{routeDetails.route_name}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Distance</b> </label>
                                                            <p style={{ marginLeft: routeDetails.distance === "" || routeDetails.distance === null? "25px" : "0" }}>{routeDetails.distance?routeDetails.distance:"-"}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>End Latitude</b> </label>

                                                            <p style={{ marginLeft: routeDetails.end_latitude === null || routeDetails.end_latitude === "" ? "25px" : "0" }}>{routeDetails.end_latitude?routeDetails.end_latitude:"-"}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>End Longitude</b> </label>

                                                            <p style={{ marginLeft: routeDetails.end_longitude === null || routeDetails.end_longitude === ""? "25px" : "0" }}>{routeDetails.end_longitude?routeDetails.end_longitude:"-"}</p>
                                                        </div>
                                                      

                                                        <div className="mb-3 col-6">
                                                             <label className="form-label"><b>End Address</b> </label>

                                                            <p style={{ marginLeft: routeDetails.end_address === null || routeDetails.end_address === ""? "25px" : "0" }}>{routeDetails.end_address?routeDetails.end_address:"-"}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                             <label className="form-label"><b>End Date</b> </label>

                                                            <p style={{ marginLeft: routeDetails.end_date === null || routeDetails.end_date === ""? "25px" : "0" }}>{routeDetails.end_date?moment(routeDetails.end_date).format('MM/DD/YYYY'):"N/A"}</p>
                                                        </div>


                                                    </div> 
                                                </div>


                                            </fieldset>

                                        </div>

                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-12">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>Route Stops</h5></legend>
                                                <div className="row">





                                                    <div className="row align-items-start">
                                                        <div className="p-3">
                                                            <div className="col-12">
                                                                <Table responsive className="table table-responsive" hover size="sm" >
                                                                    <thead className="bg-dark text-muted text-nowrap">
                                                                        <tr >
                                                                            <th className="p-0 btn-dark" variant="default">S.No</th>
                                                                            {/* <th className="p-0 btn-dark" scope="col">User Id</th>                                                                             */}
                                                                            <th className="p-0 btn-dark"> Latitude </th>
                                                                            <th className="p-0 btn-dark">Longitude </th>
                                                                            <th className="p-0 btn-dark"> Address</th>
                                                                            <th className="p-0 btn-dark">Sequence #</th>
                                                                            <th className="p-0 btn-dark">Status</th>
                                                                            {/* <th className="p-0 btn-dark">Phone Number</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    {isLoading ?
                                                                        <tbody>
                                                                            <ThreeDots
                                                                                height="80"
                                                                                width="80"
                                                                                radius="9"
                                                                                color="#0275d8"
                                                                                ariaLabel="Loading Copmanies list"
                                                                                wrapperStyle={{ position: "fixed", left: "50%" }}
                                                                                wrapperClassName=""
                                                                                visible={true}
                                                                            />
                                                                        </tbody> :
                                                                        stopList && stopList.length > 0 ? (
                                                                            <tbody class="text-black-50 " className="table-light">
                                                                               
                                                                                {stopList.map((info, index) => {
                                                                                    return (
                                                                                        <tr style={{ cursor: "pointer" }}>
                                                                                            <td >{index + 1}</td>
                                                                                            {/* <td >{info.stop_user_id}</td> */}
                                                                                            <td >{info.latitude}</td>
                                                                                            <td >{info.longitude}</td>
                                                                                            
                                                                                            <td style={{wordBreak: "break-all"}}>{info.address} </td>
                                                                                            <td>{info.sequence_number}</td>
                                                                                            <td>{info.status}</td>
                                                                                            {/* <td>{info.phone_number}</td> */}
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        ) : (

                                                                            <tbody >
                                                                                <div className="col-12">No Data found</div>
                                                                            </tbody>

                                                                        )}
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>


    );
};

export default ViewRoutes;
