import React, { useEffect, useState } from "react";
import { Button, Col, Row ,Table} from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import ReactPaginate from 'react-paginate';
import { AxiosPrivate as axios } from "../axios/axios";
import { Link, useNavigate } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';


const CompanyList = (props) => {

    const navigate = useNavigate();
    const [status, setStatus] = useState("");
    const [licenseNumber, setLicenseNumber] = useState("");
    const [dataList, setDataList] = useState([]);
    const [page, setPage] = useState(1);
    const [companyName, setCompanyName] = useState("");
    //const [totalResults, setTotalResults] = useState("0");
    const [sortType, setSortType] = useState("desc");
    const [sortBy, setSortBy] = useState("company_id");
    const sizePerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [isLoading,setIsLoading]=useState(false);
    const [remountPagination,setRemountPagination]=useState(0);
    //const [loadDefaultList, setLoadDefaultList]=useState(true);

    const linkFormatter = (id) => {
        navigate(`/view-company/${id}`);
    };

    useEffect(() => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/banner/getallcompany?CompanyName=&Status=&LicenseNumber=&page=1&size=10&sortBy=company_id&sortType=desc`;
        axios.get(url).then((res) => {
            if (res?.data?.response?.status === "success") {
                setIsLoading(false);
                setDataList(res?.data?.response?.company?.results);
                setPageCount(Math.ceil(res?.data?.response?.company?.totalResults / sizePerPage));
            }
        });

    }, []);

    const getComapanyList = (companyName, status, licenseNumber, currentPage, sizePerPage, sortField, sortOrder) => {
       // setLoadDefaultList(false);
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/banner/getallcompany?CompanyName=` + companyName +
            `&Status=` + status +
            `&LicenseNumber=` + licenseNumber +
            `&page=` + currentPage +
            `&size=` + sizePerPage +
            `&sortBy=` + sortField +
            `&sortType=` + sortOrder + ``;
        axios.get(url).then((res) => {
            debugger;
            setIsLoading(false);
            if (res?.data?.response?.status === "success") {
                setDataList(res?.data?.response?.company?.results);
               // setTotalResults(res.data.response.company.totalResults);
                setPageCount(Math.ceil(res?.data?.response?.company?.totalResults / sizePerPage));
            }
            

        });
        setSortType(sortOrder);
        setSortBy(sortField);
        setPage(currentPage);
    };
    
    useEffect(() => {
        setIsLoading(true);
        if(status!=="")
        getComapanyList (companyName, status, licenseNumber, 1, sizePerPage, "company_id", sortType);
        if(status=="")
        getComapanyList (companyName, status, licenseNumber, 1, sizePerPage, "company_id", sortType);
    }, [status]);


    const onSearchClear =() => {
        setCompanyName("");
        setLicenseNumber("");
        setStatus("");
        setPage(1);
        setRemountPagination(Math.random);//to remount pagination-- reset seelcted pagination to 1
        getComapanyList ("", "", "", 1, sizePerPage, "company_id", "desc");
    };


    const submitSearch = (val) => {
        setRemountPagination(Math.random);//to remount pagination-- reset seelcted pagination to 1
        setPage(1);
        getComapanyList (companyName, status, licenseNumber, 1, sizePerPage, "company_id", sortType);
    };

    const handlePageClick = (event) => {
        let currentPage = event.selected + 1;
        setPage(currentPage);
        getComapanyList (companyName, status, licenseNumber, currentPage, sizePerPage, sortBy, sortType);
    };


    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom">
                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Companies</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">Companies</li>
                                <li className="breadcrumb-item active" aria-current="page">List</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="row align-items-start">
                                <div className="col-10">
                                    <h5 className="mb-0 text-black fw-semibold ">Company List</h5>
                                </div>
                                <div className="col-2">
                                {/* <Link to={`/create-company`} style={{ color: "black" }}>
                                        <button class="btn btn-dark rounded-pill " type="button">Create New Company</button>
                                        
                                    </Link> */}
                                <Link to={`/create-company`} >Create Company</Link>  
                                </div>

                            </div>
                            <br />
                            <br />
                            <div className="row">

                                <Row>
                                    <Col>
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            Status
                                        </label>
                                        <div className="input-group w-auto">
                                            <select
                                            value={status|| ""}
                                                onChange={(e) => setStatus(e.target.value)}
                                                className="form-select form-select-sm text-black w-auto border-gray fs-6"
                                                aria-label=".form-select-sm example"
                                            >
                                                <option value="">
                                                    All status
                                                </option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </div>
                                    </Col>

                                    <Col>
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            Company Name
                                        </label>
                                        <div className="input-group w-auto">
                                            <input type="text" name="companyName" value={companyName}
                                                className="form-control"
                                                onChange={(e) => setCompanyName(e.target.value)}></input>

                                        </div>
                                    </Col>

                                    <Col>
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            License Number
                                        </label>
                                        <div className="input-group w-auto">
                                            <input type="text" name="licenseNumber" value={licenseNumber}
                                                className="form-control text-black w-auto border-gray fs-6"
                                                onChange={(e) => setLicenseNumber(e.target.value.toLowerCase())}></input>

                                        </div>
                                    </Col>

                                    <Col className="mt-4">
                                        <Button variant="primary" className="btn btn-dark me-4 fs-6 fw-medium rounded-pill w-130 " size="lg" type="button" onClick={submitSearch} >
                                            Search
                                        </Button>
                                        <Button variant="primary" className="btn btn-dark me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button" onClick={onSearchClear} >
                                            Clear
                                        </Button>
                                        {/* <Link to={`/create-company`} style={{ color: "black" }}>
                                            <Button variant="primary" className="me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button">
                                                Clear
                                            </Button>
                                        </Link> */}
                                    </Col>
                                </Row>



                                <div className="row align-items-start">
                                    <div className="p-3">
                                        <div className="col-12">
                                            <Table responsive className="table table-responsive "   hover size="sm" >
                                                <thead className="bg-dark text-muted text-nowrap">
                                                  <tr >
                                                    <th style={{color:" #ffff", fontWeight:"bold", paddingBottom:"5px"}} variant="default">S.No</th>
                                                        <th className="p-0 thead-dark" scope="col">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getComapanyList (companyName, status, licenseNumber, page, sizePerPage, "company_id", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                ID
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0">
                                                            <Button data-bs-toggle="modal"
                                                                role="button"
                                                                variant="default"
                                                                className="btn btn-dark"
                                                                type="button"
                                                                onClick={() => {
                                                                    getComapanyList (companyName, status, licenseNumber, page, sizePerPage, "company_name", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                               Company Name
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button></th>
                                                        <th className="p-0">
                                                            <Button
                                                                variant="default"
                                                                className="btn btn-dark "
                                                                type="button"
                                                                onClick={() => {
                                                                    getComapanyList (companyName, status, licenseNumber, page, sizePerPage, "license_number", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                License Number
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0 ">
                                                            <Button
                                                                variant="default"
                                                                className="btn btn-dark "
                                                                type="button"
                                                                onClick={() => {
                                                                    getComapanyList (companyName, status, licenseNumber, page, sizePerPage, "email", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                Email
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0">
                                                        <Button
                                                                variant="default"
                                                                className="btn btn-dark"
                                                                type="button"
                                                                onClick={() => {
                                                                    getComapanyList (companyName, status, licenseNumber, page, sizePerPage, "status", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                Status
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th scopa="col" className="btn btn-dark ">Edit</th>
                                                    </tr>
                                                </thead>
                                                {isLoading?
                                                <tbody>
                                                        <ThreeDots 
                                                        height="80" 
                                                        width="80" 
                                                        radius="9"
                                                        color="#0275d8" 
                                                        ariaLabel="Loading Copmanies list"
                                                        wrapperStyle={{ position: "fixed"  ,left: "50%"} }
                                                        wrapperClassName=""
                                                        visible={true}
                                                         />
                                                </tbody>:
                                                dataList && dataList.length > 0 ? (
                                                    <tbody class="text-black-50">
                                                        {dataList.map((info,index) => {
                                                            return (
                                                                <tr style={{ cursor: "pointer" }}  className="table-light">
                                                                    <td >{(page - 1) * 10 + (index + 1)}</td>
                                                                    <td onClick={() => linkFormatter(info?.company_id)}>{info.company_id}</td>
                                                                    <td onClick={() => linkFormatter(info?.company_id)}>{info.company_name}</td>
                                                                    <td onClick={() => linkFormatter(info?.company_id)}>{info.license_number}</td>
                                                                    <td onClick={() => linkFormatter(info?.company_id)}>{info.email}</td>
                                                                    <td onClick={() => linkFormatter(info?.company_id)} className={info.status === true ? "text-success" : "text-warning"}>
                                                                        {info.status === true? "Active" : "Inactive"}
                                                                    </td>
                                                                    <td onClick={() => navigate(`/edit-company/${info?.company_id}`)}>{<FiEdit />}</td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                ) : (
                                                    
                                                    <tbody >
                                                    <tr>
                                                        <td colspan="6" align="center"><div className="col-12">No Data found</div></td>
                                                    </tr>
                                                    
                                                </tbody>
                                                    
                                                )}
                                            </Table>
                                        </div>
                                       
                                        <div className="p-3 col-12" key={remountPagination}>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="next >"
                                                onPageChange={handlePageClick}
                                               pageRangeDisplayed={1}
                                                marginPagesDisplayed={1}
                                                pageCount={pageCount}
                                                previousLabel="< previous"
                                                renderOnZeroPageCount={null}
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                selectedPageRel={page}
                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>

    );
};

export default CompanyList;
