import React, { useEffect, useState } from "react";
import { AxiosPrivate as axios } from "../axios/axios";

import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import { FaImage, FaLongArrowAltRight, FaChartArea, FaChartPie } from "react-icons/fa";

const DashboardMain = (props) => {
    const [dashboardData, setDashboardData] = useState([]);
    const [activeBannerPaths, setActiveBannerPaths] = useState([]);
    const [userChartData, setUserChartData] = useState([]);
    const [routeChartData, setRouteChartData] = useState([]);

    const options = {
        isStacked: true,
        height: 400,
        legend: { position: "bottom", maxLines: 3 },
        vAxis: { minValue: 0 },
    };

    const options1 = {
        title: "Routes by Status",
        is3D: true,
    };

    useEffect(() => {
        const fetchData = async () => {
            const dashboradData = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard`);
            const activeBannerPaths = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/getbannerdisplay`);
            const userData = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/userschart`);
            const routeData = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/routechart`);
            setDashboardData(dashboradData?.data);
            setActiveBannerPaths(activeBannerPaths?.data?.response?.imageURL);
            setUserChartData(userData?.data?.users);
            setRouteChartData(routeData?.data);

        };
        fetchData();
    }, []);




    return (
        <>
            <div class="container-fluid py-3 border-top border-bottom">
                <div class="row">
                    <div class="col">
                        <h5 class="mb-0">Dashboard</h5>
                    </div>
                    <div class="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mb-0">
                                <li class="breadcrumb-item">Dashboard</li>
                                <li class="breadcrumb-item active" aria-current="page">Main</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="container-fluid px-4">

                <ol class="breadcrumb mb-4">
                </ol>
                <div class="row">
                    <div className="col-xl-3 col-md-6">
                        <Link to="/usersList">
                            <div className="card bg-info text-white mb-4">
                                <div class="card-body">
                                    <p class="mb-0 mb-2">Number of Users</p>
                                    <p class="mb-0"> <h3>{dashboardData ? dashboardData.usercount : "0"}</h3></p>
                                </div>
                                <div class="card-footer d-flex align-items-center  justify-content-between">
                                    <button type="button" class="btn btn-link text-white">view more</button>
                                    <div class="small text-white"><i ><FaLongArrowAltRight /></i></div>
                                </div>
                            </div>
                        </Link>

                    </div>
                    <div class="col-xl-3 col-md-6">
                        <Link to="/CompanyList">
                            <div class="card bg-warning text-white mb-4">
                                <div class="card-body">
                                    <p class="mb-0 mb-2">Number of Companies</p>
                                    <p class="mb-0 "> <h3>{dashboardData ? dashboardData.companiescount : "0"}</h3></p>
                                </div>
                                <div class="card-footer d-flex align-items-center justify-content-between">
                                    <button type="button" class="btn btn-link text-white">view more</button>
                                    <div class="small text-white"><i ><FaLongArrowAltRight /></i></div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-xl-3 col-md-6">
                        <Link to="/routeList">
                            <div class="card bg-success text-white mb-4">
                                <div class="card-body">
                                    <p class="mb-0 mb-2">Number of Routes</p>
                                    <p class="mb-0"> <h3>{dashboardData ? dashboardData.userroutecount : "0"}</h3></p>
                                </div>
                                <div class="card-footer d-flex align-items-center justify-content-between">
                                    <button type="button" class="btn btn-link text-white">view more</button>
                                    <div class="small text-white"><i ><FaLongArrowAltRight /></i></div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-xl-3 col-md-6">

                        <div class="card bg-danger text-white mb-4">
                            <div class="card-body">
                                <p class="mb-0 mb-2">Revenue - for Current Month</p>

                                <p class="mb-0"> <h3>${dashboardData.bannercost}</h3></p>
                            </div>
                            <div class="card-footer d-flex align-items-center justify-content-between">
                                <button type="button" class="btn btn-link text-white" style={{ paddingBottom: "25px" }}></button>
                                {/* <div class="small text-white"><i ><FaLongArrowAltRight/></i></div>   */}
                            </div>
                        </div>

                    </div>

                </div>
                <div class="row">
                    <div class="col-xl-6">
                        <div class="card mb-4">
                            <div class="card-header">
                                <i class=" me-1"><FaImage /></i>
                                Header Banner
                            </div>
                            <div class="card-body">
                                <div class="card-body" >
                                    <div className="rounded col-md-6 text-left"  >
                                    {activeBannerPaths && activeBannerPaths?.first_image_URL ? <img src={activeBannerPaths?.first_image_URL} className="rounded w-100"></img> : <span>Banner not available</span>}
                                        {/* <img src={activeBannerPaths?.first_image_URL} alt="Banner not available" className="rounded w-100"></img> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="card mb-4">
                            <div class="card-header">
                                <i class=" me-1"><FaImage /></i>
                                Footer Banner
                            </div>
                            <div class="card-body">
                            <div class="card-body" >
                                    <div className="rounded col-md-6 text-left"  >
                                        {activeBannerPaths && activeBannerPaths?.second_image_URL ? <img src={activeBannerPaths?.second_image_URL} className="rounded w-100"></img> : <span>Banner not available</span>}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6">
                        <div class="card mb-4">
                            <div class="card-header">
                                <i class=" me-1"><FaChartArea /></i>
                                Users
                            </div>
                            <div class="card-body">
                                {userChartData ?
                                    <Chart
                                        chartType="AreaChart"
                                        width="100%"
                                        height="40"
                                        data={userChartData}
                                        options={options}
                                    /> : <div>
                                        No Data Available
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="card mb-4">
                            <div class="card-header">
                                <i class="me-1"><FaChartPie /></i>
                                Routes by Status
                            </div>
                            <div class="card-body">
                                {userChartData ?
                                    <Chart
                                        chartType="PieChart"
                                        data={routeChartData}
                                        options={options1}
                                        width={"100%"}
                                        height={"400px"}
                                    /> : <div>
                                        No Data Available
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>

    );
};

export default DashboardMain;
