import React, { useEffect, useState } from "react";
import "./style.css";
import { useParams, Link } from "react-router-dom";
import { Table} from "react-bootstrap";
import { AxiosPrivate as axios } from "../axios/axios";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

import { ThreeDots } from 'react-loader-spinner';



const ViewUsers = (props) => {
    //const navigate = useNavigate();
    let params = useParams();
    const [userDetails, setUserDetails] = useState({});
    const [deviceList, setDeviceList] = useState([]);
    const [isLoading,setIsLoading]=useState(false);
    const [imagePreview, setImagePreview] = useState("https://dummyimage.com/100x100/ddd/000");

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/userdetails`, {
                params: {
                    user_id: `${params.id}`,
                },
            })
            .then((res) => {
                setIsLoading(false);
                setUserDetails(res?.data?.response?.userDetail);
                setDeviceList(res?.data?.response?.userDetail?.user_devices);
                setImagePreview(res?.data?.response?.company?.logo_image_url);
            });
    }, [params.id]);

  
    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Users</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/usersList">Users</Link>

                                </li>
                                <li className="breadcrumb-item active" aria-current="page">View User Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="p-3">
                                <form action="">
                                    <div className="row">
                                        <div className="col-12">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>Profile Details</h5></legend>
                                                <div className="row">

                                                    <div className="col-6">
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>User Id</b> </label>
                                                            <p >{userDetails.user_id}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>First Name</b></label>
                                                            <p >{userDetails.first_name}</p>
                                                        </div>

                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Last Name</b> </label>

                                                            <p>{userDetails.last_name}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Contact Number</b> </label>

                                                            <p>{userDetails.phone_number}</p>
                                                        </div>


                                                    </div>

                                                    <div className="col-6">
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Email</b> </label>

                                                            <p>{userDetails.email}</p>
                                                        </div>
                                                        <div className="mb-3 col-6">
                                                            <label className="form-label"><b>Created At</b> </label>
                                                            <p >{moment(userDetails.createdAt).format("MM/DD/YYYY HH:MM")}</p>
                                                        </div>
                                                        <div className="mb-3 col-8">
                                                            <label className="form-label"><b>Avatar</b> </label>
                                                            <p><img style={{ height: "100px", width: "200px", marginLeft: "10px", marginTop: "1%" }} alt="..." src={imagePreview} /></p>
                                                        </div>
                                                        <div></div>

                                                    </div>
                                                </div>


                                            </fieldset>

                                        </div>

                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-12">
                                            <fieldset className="border rounded-3 p-3">
                                                <legend className="float-none w-auto px-3"><h5>User Logged Devices</h5></legend>
                                                <div className="row">





                                                    <div className="row align-items-start">
                                                        <div className="p-3">
                                                            <div className="col-12">
                                                                <Table responsive className="table table-responsive" hover size="sm" >
                                                                    <thead className="bg-dark text-muted text-nowrap">
                                                                        <tr >
                                                                            <th className="p-0 btn-dark" variant="default">S.No</th>
                                                                            <th className="p-0 btn-dark" scope="col">User Device Id </th>
                                                                            <th className="p-0 btn-dark"> Device Id </th>
                                                                            <th className="p-0 btn-dark">Device Type  </th>
                                                                            <th className="p-0 btn-dark">Latest Version </th>
                                                                            <th className="p-0 btn-dark"> Device OS</th>
                                                                            <th className="p-0 btn-dark">Last Login  </th>
                                                                        </tr>
                                                                    </thead>
                                                                    {isLoading ?
                                                                        <tbody>
                                                                            <ThreeDots
                                                                                height="80"
                                                                                width="80"
                                                                                radius="9"
                                                                                color="#0275d8"
                                                                                ariaLabel="Loading Copmanies list"
                                                                                wrapperStyle={{ position: "fixed", left: "50%" }}
                                                                                wrapperClassName=""
                                                                                visible={true}
                                                                            />
                                                                        </tbody> :
                                                                        deviceList && deviceList.length > 0 ? (
                                                                            <tbody class="text-black-50 " className="table-light">
                                                                                {deviceList.map((info, index) => {
                                                                                    return (
                                                                                        <tr style={{ cursor: "pointer" }}>
                                                                                            <td >{index + 1}</td>
                                                                                            <td >{info.user_device_id}</td>
                                                                                            <td >{info.device_id}</td>
                                                                                            <td >{info.device_type}</td>
                                                                                            <td >{info.latest_version}</td>
                                                                                            <td >{info.device_os}
                                                                                            </td>
                                                                                            <td>{moment(info.last_login).format("YYYY-MM-DD HH:MM A")}</td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                            </tbody>
                                                                        ) : (

                                                                            <tbody >
                                                                                <div className="col-12">No Data found</div>
                                                                            </tbody>

                                                                        )}
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>


    );
};

export default ViewUsers;
