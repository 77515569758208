import { AxiosPrivate as axios } from "../components/axios/axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import validator from 'validator';
import moment from "moment";

const EditBanner = (props) => {
    let params = useParams();
    //const [bannerData, setBannerData] = useState([]);

    const [companies, setCompanies] = useState([]);
    const [title, setTitle] = useState();
    const [url, setUrl] = useState();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [status, setStatus] = useState("");
    const [bannerType, setBanneType] = useState();
    const [BannerImagePath, setBannerImagePath] = useState();
    const [selectedFile1, setSelectedFile1] = useState([]);
    const [imagePreview, setImagePreview] = useState("https://dummyimage.com/100x100/ddd/000");
    const [pdfFilePath, setPdfFilePath] = useState("");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const [notificationError, setNotificationError] = useState("");
    const [companyId, setCompanyId] = useState("");
    const [agreementPdf, setAgreementPdf] = useState("");
    const [bannerCost, setBannerCost] = useState(0);
    const [awsImageSuccess, setAwsImageSuccess] = useState(true);
    const [awsPdfSuccess, setAwsPdfSuccess] = useState(true);
    const [pdfFilename,setPdfFilename]=useState("");
    const [imageFilename,setImageFilename]=useState("");
    const [pdfPreview,setPdfPreview]=useState("");

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/banner/getallcompanyid`)
            .then((res) => {
                if (res?.data?.response?.status === "success") {
                    setCompanies(res?.data?.response?.company);
                }
            });
    }, []);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/banner/getBannerById`, {
                params: {
                    BannerId: `${params.id}`,
                },
            })
            .then((res) => {

                if (res?.data?.response?.status === "success") {
                    setShowErrorNotification(false);
                    let result=res?.data?.response?.banner?.details;
                    setTitle(result?.title);
                    setUrl(result?.banner_url);
                    setStartDate(result?.start_Date ? moment(result?.start_Date).toDate(): "");
                    setEndDate(result?.end_Date ? moment(result?.end_Date).toDate() : "");
                    // setEndDate(result?.end_Date ? new Date(result?.end_Date) : "");
                    setStatus(result?.is_active);
                    setBanneType(result?.banner_type);
                    setBannerImagePath(result?.banner_image_path);
                    setImagePreview(res?.data?.response?.banner?.banner_image_path);
                    setPdfFilePath(result?.pdf_file_name);
                    setPdfPreview(res?.data?.response?.banner?.pdf_file_url);
                    setBannerCost(result?.banner_cost);
                    setCompanyId(result?.company_id);

                }
                else {
                    window.scrollTo(0, 0);
                    setShowErrorNotification(true);
                    setNotificationError(res.data.response.message);
                }
            });
    }, [params.id]);

    const validateForm = () => {
        let isFormValid = true;
        const formErrors = {};
        var today = new Date();
        var current_date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
        if (!title) {
            formErrors.title = "Please enter banner title";
            isFormValid = false;
        }
        if (!startDate) {
            formErrors.startDate = "Please select start date";
            isFormValid = false;
        }
        else {
            if (!validator.isDate(new Date(startDate))) {
                formErrors.startDate = "Please select valid start date";
                isFormValid = false;
            }
            else {
                if (startDate < current_date) {
                    formErrors.startDate = "Start date should be before today";
                    isFormValid = false;
                }
            }
        }


        if (endDate) {
            if (!validator.isDate(new Date(endDate))) {
                formErrors.endDate = "Please select valid end date";
                isFormValid = false;
            }
            else {
                if (endDate < startDate) {
                    formErrors.endDate = "End date should be greater than or equal to start date";
                    isFormValid = false;
                }
            }
        }
        if (url && !validator.isURL(url)) {
            formErrors.url = "Please enter valid URL";
            isFormValid = false;
        }

        if (!BannerImagePath && selectedFile1.length <= 0) {
            formErrors.selectedFile1 = "Please upload image";
            isFormValid = false;
        }
        if (!pdfFilePath && agreementPdf.length <= 0) {
            formErrors.agreementPdf = "Please upload agreement pdf";
            isFormValid = false;
        }

        if (!bannerCost) 
        {
            formErrors.bannerCost = "Please enter banner cost";
            isFormValid = false;
        }
        if (!companyId) {
            formErrors.companyId = "Please select Company name";
            isFormValid = false;
        }

        setErrors(formErrors);
        return isFormValid;
    };
    const onSubmitClick = async (e) => {
        e.preventDefault();
        var formData = {

            BannerId: `${params.id}`,
            Title: title,
            BannerImagePath: (selectedFile1.name) ? imageFilename : BannerImagePath,
            BannerType: bannerType,
            Status: status === true ? "Active" : "InActive",
            StartDate: startDate,
            EndDate: endDate,
            BannerURL: url,
            CompanyId: companyId,
            BannerCost: bannerCost,
            PdfName: (agreementPdf.name) ? pdfFilename : pdfFilePath
        };
        if (validateForm()) {
          
            //No Files(banner Inmage/ agreement pdf) has been changed
            if(!selectedFile1.name && !agreementPdf.name)
            {
                updateBannerDetails(formData);
            }
            else{
            
                if (!awsImageSuccess && selectedFile1.name) {
                    await axios.get(`${process.env.REACT_APP_API_S3_URL}/v1/s3/put-presigned-url?fileName=${imageFilename}`).then(async (res) => {
                        const { data } = res;
                        const url = data.url;
                        if (url !== "") {
                            await axios({
                                method: "put",
                                url: url,
                                data: selectedFile1,
                            }).then((res) => {
                                setAwsImageSuccess(true);
                            }).catch((err) => {
                                window.scrollTo(0, 0);
                                setShowErrorNotification(true);
                                setNotificationError("Something went wrong... Please contact admin!!!");
                            });
    
                        }
    
                    });
                }
    
                if (!awsPdfSuccess && agreementPdf.name) {
                    await axios.get(`${process.env.REACT_APP_API_S3_URL}/v1/s3/put-presigned-url?fileName=${pdfFilename}`).then(async (res) => {
                        const { data } = res;
                        const url = data.url;
                        if (url !== "") {
                           await axios({
                                method: "put",
                                url: url,
                                data: agreementPdf,
                                // headers: { "Content-Type": "image/jpeg", "Access-Control-Allow-Origin": "*" },
                                // crossDomain: true,
                            }).then((res) => {
                                setAwsPdfSuccess(!awsPdfSuccess);
                            }).catch((err) => {
                                window.scrollTo(0, 0);
                                setShowErrorNotification(true);
                                setNotificationError("Something went wrong... Please contact admin!!!");
                            });
                        }
    
                    });
                }
             updateBannerDetails(formData);
                
            }
        }
    };


    

    function updateBannerDetails(formData) {
        axios.post(`${process.env.REACT_APP_API_URL}/banner/edit`, formData).then((res) => {
            let status = res.data.response.status;
            if (status === "success") {
                toast.success(res.data.response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setTitle("");
                setStartDate("");
                setEndDate("");
                setUrl("");
                let uploadImage1 = document.getElementById("img1");
                uploadImage1.value = "";
                setShowErrorNotification(false);
                setNotificationError("");
                navigate("/BannerList");
            }
            else {
                setShowErrorNotification(true);
                setNotificationError(res.data.response.message);
            }
            //return res;
        });
    }


    const handleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    const handleOptionChange = (e) => {
        setStatus(e.target.value === "Active" ? true : false);
    };

    const handleBannerTypeChange = (e) => {
        setBanneType(e.target.value);
    };

    const onSelectPdf = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setAwsPdfSuccess(true);
            setAgreementPdf(undefined); 
        }
        else {
            let pdf=Date.now() + e.target.files[0].name;
            setPdfFilename(pdf);
            setAwsPdfSuccess(false);
            setAgreementPdf(e.target.files[0]);      
        }

    };

    const onSelectFile1 = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setAwsPdfSuccess(true);
            setSelectedFile1(undefined);
        }
        else {
            let img=Date.now() + e.target.files[0].name;
            setImageFilename(img);
            setAwsImageSuccess(false);
            setSelectedFile1(e.target.files[0]);
            setImagePreview(URL.createObjectURL(e.target.files[0])); 
        }

    };

    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Banner</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/BannerList">Banner</Link>

                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Edit Banner Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="p-3">
                                <h5 className="mb-0">Edit Banner Details</h5>
                            </div>
                            {/* <div className="mb-3 col-4">
                                <div className="rounded col-md-4 text-left"  >
                                     <img class="rounded w-100" alt="..." src={imagePreview} />
                                
                                </div>
                            </div> */}
                            {showErrorNotification ?
                                <div className="alert alert-danger col-4" role="alert">
                                    {notificationError}
                                </div> : null}

                            <div className="p-3">
                                <form action="">
                                    <fieldset className="border rounded-3 p-3">
                                        <legend className="float-none w-auto px-3"><h5>Banner Details</h5></legend>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Banner Title</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="banner_title" placeholder="Enter banner title" value={title} onChange={handleChange} />
                                                    {errors.title && <p className="errorMsg">{errors.title}</p>}
                                                </div>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>URL</b></label>
                                                    <input type="text" className="form-control" name="url"
                                                        placeholder="Enter URL "
                                                        value={url}
                                                        onChange={handleUrlChange} />
                                                    {errors.url && <p className="errorMsg">{errors.url}</p>}
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Banner Type </b><i className="star">*</i></label>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Bannertype" id="inlineRadio1" value="Header" checked={bannerType === "Header"} onChange={handleBannerTypeChange} />
                                                            <label className="form-check-label" for="inlineRadio1">Header</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="Bannertype" id="inlineRadio2" value="Footer" checked={bannerType === "Footer"} onChange={handleBannerTypeChange} />
                                                            <label className="form-check-label" for="inlineRadio2">Footer</label>
                                                        </div>
                                                    </div>
                                                    {errors.bannerType && <p className="errorMsg">{errors.bannerType}</p>}

                                                </div>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Banner Status </b><i className="star">*</i></label>
                                                    <div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="status" id="inlineRadio1" value="Active" checked={status === true} onChange={handleOptionChange} />
                                                            <label className="form-check-label" for="inlineRadio1">Active</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" name="status" id="inlineRadio2" value="InActive" checked={status === false} onChange={handleOptionChange} />
                                                            <label className="form-check-label" for="inlineRadio2">In Active</label>
                                                        </div>
                                                    </div>
                                                    {errors.status && <p className="errorMsg">{errors.status}</p>}
                                                </div></div>
                                            <div className="col-6">

                                                <div className="mb-3 col-8">
                                                    <label className="form-label font-weight-bold"><b>Start Date </b><i className="star">*</i></label>

                                                    <DatePicker
                                                        className="form-control"
                                                        selected={startDate}
                                                        placeholderText={"MM/DD/YYYY"}
                                                        onChange={(date) => setStartDate(date)}
                                                        showTimeSelect
                                                        minDate={new Date()}
                                                        //maxDate={addMonths(new Date(), 5)}
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="MM/dd/yyyy hh:mm"
                                                    />
                                                    {errors.startDate && <p className="errorMsg">{errors.startDate}</p>}
                                                </div>

                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>End Date</b></label>
                                                    <DatePicker
                                                        className="form-control"
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                        minDate={new Date()}
                                                        placeholderText={"MM/DD/YYYY"}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="MM/dd/yyyy hh:mm"
                                                    />
                                                    {errors.endDate && <p className="errorMsg">{errors.endDate}</p>}

                                                </div>
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Upload Image</b> <i className="star">*</i></label>
                                                    <div className="d-flex mb-3">
                                                        <input type="file" className="form-control" placeholder="select banner image" name="selectedFile1"
                                                            accept=".jpg, .jpeg"
                                                            id={"img1"}
                                                            //value={selectedFile}
                                                            onChange={onSelectFile1} />

                                                    </div>
                                                    <div>{errors.selectedFile1 && <p className="errorMsg">{errors.selectedFile1}</p>}</div>
                                                </div>
                                                <div className="mb-3 col-4">
                                                    <div className="rounded col-md-4 text-left"  >
                                                        <img src={imagePreview} className="rounded w-100" alt="..." />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </fieldset>
                                    <br />
                                    <fieldset className="border rounded-3 p-3">
                                        <legend className="float-none w-auto px-3"><h5>Company Details</h5></legend>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Cost</b> <i className="star">*</i></label>

                                                    <input type="text" className="form-control" name="bannerCost" placeholder="Enter cost" value={bannerCost} onChange={(e) => setBannerCost(e.target.value)} />
                                                    {errors.bannerCost && <p className="errorMsg">{errors.bannerCost}</p>}
                                                </div>



                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Company Name</b><i className="star">*</i></label>
                                                    <div>
                                                        <select className="form-control form-select form-select-sm  text-black-50 fs-6 me-2 align-self-center col-2 " value={companyId} defaultValue={companyId} onChange={(e) => setCompanyId(e.target.value)}>
                                                            <option value="0">Select Company</option>
                                                            {companies.map((item) =>
                                                                <option value={item.company_id}>
                                                                    {item.company_name}
                                                                </option>
                                                            )
                                                            }
                                                        </select>
                                                    </div>
                                                    {errors.companyId && <p className="errorMsg">{errors.companyId}</p>}

                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <div className="mb-3 col-8">
                                                    <label className="form-label"><b>Upload  Aggreement</b> <i className="star">*</i></label>
                                                    <div className="d-flex mb-3">
                                                        <input type="file" className="form-control" placeholder="select agreement file" name="agreementPdf"
                                                            accept=".pdf"
                                                            id={"agreementPdf"}
                                                            onChange={onSelectPdf} />

                                                    </div>
                                                    <div>{pdfPreview && <a href={pdfPreview} >{pdfFilePath}</a>}</div>
                                                    <div>{errors.agreementPdf && <p className="errorMsg">{errors.agreementPdf}</p>}</div> 

                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div className="form-group">
                                        <button className="me-4 fw-medium  btn btn-primary btn-lg" style={{ marginRight: 16 }} type="button" onClick={onSubmitClick}>Update</button>
                                        <button className="me-4 fw-medium  btn btn-primary btn-lg" type="button" onClick={() => { navigate("/BannerList"); }}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default EditBanner;
