import { AxiosPrivate as axios } from "../components/axios/axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams,useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const ViewBanner = (props) => {
    let params = useParams();
    const navigate = useNavigate();
    const [bannerData, setBannerData] = useState("");
    const [imagePreview, setImagePreview] = useState("https://dummyimage.com/100x100/ddd/000");
    const [pdfPreview,setPdfPreview]=useState("");

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/banner/getBannerById`, {
                params: {
                    BannerId: `${params.id}`,
                },
            })
            .then((res) => {
                if (res?.data?.response?.status === "success") {
                    setBannerData(res?.data?.response?.banner?.details);
                    setImagePreview(res?.data?.response?.banner?.banner_image_path);
                    setPdfPreview(res?.data?.response?.banner?.pdf_file_url);
                }
             
            });
    }, [params.id]);

    const editDetails = async (e) => {
        navigate(`/edit-banner/${params.id}`);
    }



    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom">

                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Banner</h4>
                    </div>
                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">
                                    <Link to="/BannerList">Banner</Link>

                                </li>
                                <li className="breadcrumb-item active" aria-current="page">View Banner</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                    
                        <div className="bg-light p-3 mt-3">
                        <div className="col-12 row">
                            <div className="p-3 col-10">
                                <h5 className="mb-0">View Banner Details</h5>

                            </div>
                            <div className="p-3  col-2">
                                <button class="form-control  btn btn-outline-default waves-effect"  onClick={editDetails}>Edit Banner Details</button>
                        
                             </div>
                             </div>
                            <div className="p-3">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="mt-3 rounded-4">
                                            <div className="p-4">
                                                <div className="container">
                                                    <div style={{ width: "200" }}>
                                                        <Row>
                                                            <Col>
                                                                <h4 className="text-black fw-semibold">{bannerData.title}</h4>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginTop: "20px" }}>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        Banner Title
                                                                    </label>
                                                                    <div>
                                                                        <p>{bannerData.title}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        Start Date
                                                                    </label>
                                                                    <div>
                                                                        <p>{bannerData.start_Date?moment(bannerData.start_Date).format("MM/DD/YYYY hh:mm"):"N/A"} </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        Banner Type
                                                                    </label>
                                                                    <div>
                                                                        <p>{bannerData.banner_type}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        End Date
                                                                    </label>
                                                                    <div>
                                                                        <p>{bannerData.end_Date?moment(bannerData.end_Date).format("MM/DD/YYYY hh:mm"):"N/A"} </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        URL
                                                                    </label>
                                                                    <div>
                                                                        <p>{bannerData.banner_url} </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        Company Name
                                                                    </label>
                                                                    <div>
                                                                        <p className="form-label fw-bold"> {bannerData.company_id}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                           
                                                        </Row>

                                                        <Row>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        Banner Cost
                                                                    </label>
                                                                    <div>
                                                                        <p>{bannerData.banner_cost} </p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                        Status
                                                                    </label>
                                                                    <div>
                                                                        <p className={bannerData.is_active === true ? "text-success" : "text-warning"}> {bannerData.is_active ? "Active" : "Inactive"}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                           
                                                        </Row>

                                                        <Row>
                                                            <Col>
                                                                <label for="exampleFormControlInput1" className="form-label text-black  fw-bold">
                                                                    Banner Image
                                                                </label>
                                                                <div>
                                                                    <img style={{ height: "200px", width: "300px", marginLeft: "10px", marginTop: "1%" }} alt="..." src={imagePreview} />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="mb-4">
                                                                    <label for="exampleFormControlInput1" className="form-label fw-bold">
                                                                    Agreement File
                                                                    </label>
                                                                    <div>
                                                                    <div>{pdfPreview && <a href={pdfPreview} >{bannerData.pdf_file_name}</a>}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                        </Row>

                                                        </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div></div></div></div></div>
            </div>
        </>

    );
};

export default ViewBanner;
