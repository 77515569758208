import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { token } from "./utils/Utils";

import BannerList from "./pages/BannerList";
// import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import ChangePassword from "./components/auth/ChangePassword";
import SideNav from "./components/layout/SideNav";
import Header from "./components/layout/Header";
import CreateBanner from "./pages/CreateBanner";
import EditBanner from "./pages/EditBanner";
import BannerView from "./pages/BannerView";
import UsersList from "./components/Users/UsersList"
import CompanyList from './components/Company/CompanyList';
import CreateCompany from "./components/Company/CreateCompany";
import EditCompany from "./components/Company/EditCompany";
import ViewCompany from "./components/Company/ViewCompany";
import AppDownload from './components/AppDownload/appDownload';
import ViewUsers from "./components/Users/ViewUsersDetails";
import UsersRoutesList from "./components/Users/UserRoutes";
import ViewRoutesDetails from './components/Routes/ViewRoutesDetails';
import Dashboard from './components/Dashboard/DahboardMain';
import RouteList from './components/Routes/RouteList';
// import AxiosPrivate from "./components/axios/axios"
//import Logo from "../../images/URLane.png";

function RequireAuth({ children }) {
	let tokens = localStorage.getItem("token");
	return tokens ? children : <Navigate to={"/login"} />;
}
function AfterLogin({ children }) {
	let tokens = localStorage.getItem("token");
	return !tokens ? children : <Navigate to={"/"} />;
}

function Routers() {
	return (

		<>
			<Router>
				<div class="container-fluid position-relative w-100 h-100">
					<div class="row h-100">
						{token && <SideNav />}
						<div class="col-sm-10 px-0 h-100">
							<div class="w-100 h-100 d-flex flex-column pb-3">
								{token && <Header />}
								<div class="col-sm-12 px-0 h-100">
									<Routes>
										<Route
											path="/login"
											element={
												<AfterLogin>
													<Login />
												</AfterLogin>
											}
										/>

										<Route
											path="/change-password"
											element={
												<RequireAuth>
													<ChangePassword />
												</RequireAuth>
											}
										/>

										{/* <Route
											path="/forgot-password"
											element={
												<AfterLogin>
													<ForgotPassword />
												</AfterLogin>
											}
										/> */}
										<Route
											path="/"
											element={
												<RequireAuth>
                                                 <Dashboard />
												</RequireAuth>
												
											}
										/>
										<Route
											path="/Dashboard"
											element={
												<RequireAuth>
                                                    <Dashboard />
												</RequireAuth>
												
											}
										/>
										<Route
											path="/BannerList"
											element={
												<RequireAuth>
													<BannerList />
												</RequireAuth>
											}
										/>
											<Route
											path="/RouteList"
											element={
												<RequireAuth>
													<RouteList />
												</RequireAuth>
											}
										/>
										{/* <Route
											path="/"
											element={
												<RequireAuth>
													<BannerList />
												</RequireAuth>
											}
										/> */}
										<Route
											path="/create-banner"
											element={
												<RequireAuth>
													<CreateBanner />
												</RequireAuth>
											}
										/>
										<Route
											path="/edit-banner/:id"
											element={
												<RequireAuth>
													<EditBanner />
												</RequireAuth>
											}
										/>
										<Route
											path="/view-banner/:id"
											element={
												<RequireAuth>
													<BannerView />
												</RequireAuth>
											}
										/>

										<Route
											path="/view-company/:id"
											element={
												<RequireAuth>
													<ViewCompany />
												</RequireAuth>
											}
										/>
										<Route
											path="/companyList"
											element={
												<RequireAuth>
													<CompanyList />
												</RequireAuth>
											}
										/>
										<Route
											path="/create-company"
											element={
												<RequireAuth>
													<CreateCompany />
												</RequireAuth>
											}
										/>
										<Route
											path="/edit-company/:id"
											element={
												<RequireAuth>
													<EditCompany />
												</RequireAuth>
											}
										/>
										<Route
											path="/usersList"
											element={
												<RequireAuth>
													<UsersList />
												</RequireAuth>
											}
										/>
										<Route
											path="/users-routes/:id"
											element={
												<RequireAuth>
													<UsersRoutesList />
												</RequireAuth>
											}
										/>


										<Route path="/view-user/:id"
											element={
												<RequireAuth>
													<ViewUsers />
												</RequireAuth>
											}></Route>

										<Route path="/view-route-details/:id"
											element={
												<RequireAuth>
													<ViewRoutesDetails />
												</RequireAuth>
											}></Route>
										<Route
											path="/AppDownload"
											element={
												<AppDownload />
											}
										/>





									</Routes>

								</div>
							</div>
						</div>
					</div>
				</div>


			</Router>
		</>
	);
}

export default Routers;
