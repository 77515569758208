import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import { AxiosPrivate as axios } from "../axios/axios";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Checkmark } from 'react-checkmark';
import { IoMdMore } from "react-icons/io";




const UsersList = (props) => {

    const navigate = useNavigate();
    //const [status, setStatus] = useState("");
    const [userList, setUserList] = useState([]);
    const [page, setPage] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    //const [totalResults, setTotalResults] = useState("0");
    const [sortType, setSortType] = useState("desc");
    const [sortBy, setSortBy] = useState("user_id");
    const sizePerPage = 10;
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [remountPagination, setRemountPagination] = useState(0);
    // const [showErrorNotification, setShowErrorNotification] = useState(false);
    // const [notificationError, setNotificationError] = useState("");
    // const [bannerCost,setBannerCost]=useState("");
    const [userSoftDelete, setuserSoftDelete] = useState("");
    const [modalPopup, setModalPopup] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState();
    const [menuInfo, setMenuInfo] = useState();
    const dropdownRef = useRef(null);
    const linkFormatter = (id) => {
        navigate(`/view-user/${id}`);
    };

    const softDelete = async (id, type) => {
        console.log(type, "ppppp");
        let formData = {
            user_id: id
        };
        if (type == "active" || type == 1) {
            await axios.post(`${process.env.REACT_APP_API_S3_URL}v1/user/deleteuseraccount`, formData).then((res) => {
                let status = res?.data?.response?.status;

                if (status === "success") {
                    toast.success("User Account Deactivated Successfully", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    getUsersList(name, email, page, sizePerPage, "user_id", "desc");
                }
            }
            )
        }
        else {
            await axios.post(`${process.env.REACT_APP_API_S3_URL}v1/user/activateuseraccount`, formData).then((res) => {
                let status = res?.data?.response?.status;

                if (status === "success") {
                    toast.success(res?.data?.response?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });


                    // navigate("/usersList");
                    getUsersList(name, email, page, sizePerPage, "user_id", "desc");
                }
                else {
                    window.scrollTo(0, 0);
                }
            });
        }
    };


    const sendResetPasswordOtp = async (email) => {
        let formData = {
            email: email.toString()
        };
        await axios.post(`${process.env.REACT_APP_API_S3_URL}v1/auth/forgot-password`, formData).then((res) => {
            let status = res?.data?.response?.status;

            if (status === "success") {
                toast.success("Verification code generated and sent successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // getUsersList(name, email, page, sizePerPage, "user_id", "desc");
            } else {
                toast.warning("You have reached the maximum otp limit.Please try after sometime", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        )

    };


    const linkUseroutes = (id) => {
        navigate(`/users-routes/${id}`);
    };



    useEffect(() => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/dashboard/getuserlist?search=&email=&page=1&size=10&sortBy=user_id&sortType=desc`;
        axios.get(url).then((res) => {
            if (res?.data?.response?.status === "success") {
                setIsLoading(false);
                setUserList(res?.data?.response?.results);
                setPageCount(Math.ceil(res?.data?.response?.totalResults / sizePerPage));
            }
        });

    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const getUsersList = (name, email, currentPage, sizePerPage, sortField, sortOrder) => {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_URL}/dashboard/getuserlist?search=` + name +
            `&email=` + encodeURIComponent(email) +
            `&page=` + currentPage +
            `&size=` + sizePerPage +
            `&sortBy=` + sortField +
            `&sortType=` + sortOrder + ``;
        axios.get(url).then((res) => {
            setIsLoading(false);
            if (res.data.response.status === "success") {
                setUserList(res?.data?.response?.results);
                setPageCount(Math.ceil(res?.data?.response?.totalResults / sizePerPage));
            }


        });
        setSortType(sortOrder);
        setSortBy(sortField);
        setPage(currentPage);
    };



    const onSearchClear = () => {
        setName("");
        setEmail("");
        setRemountPagination(Math.random);
        getUsersList("", "", 1, sizePerPage, "user_id", sortType);
    };


    const submitSearch = () => {
        setRemountPagination(Math.random);
        setPage(1);
        getUsersList(name, email, 1, sizePerPage, "user_id", sortType);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handlePageClick = (event) => {
        let currentPage = event.selected + 1;
        setPage(currentPage);
        getUsersList(name, email, currentPage, sizePerPage, sortBy, sortType);
    };

    const renderModalPopup = () => {
        return (
            <>
                {modalPopup &&
                    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "350px" }}>
                            <div className="modal-content" >
                                <div className="modal-body mt-3">
                                    Are you sure you want to {userSoftDelete} this user account?
                                    <div className="d-flex justify-content-end mt-3 gap-2">
                                        <button type="button" className="btn btn-secondary" onClick={() => setModalPopup(false)}>Close</button>
                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            softDelete(deleteInfo?.user_id, deleteInfo?.is_active);
                                            setModalPopup(false)
                                        }} >Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }


    return (
        <>
            <div className="container-fluid py-3 border-top border-bottom" onClick={() => setDropdownOpen(false)}>
                <div className="row">
                    <div className="col">
                        <h4 className="mb-0">Users</h4>
                    </div>
                    {renderModalPopup()}

                    <div className="col-auto ms-auto">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0">
                                <li className="breadcrumb-item">Users</li>
                                <li className="breadcrumb-item active" aria-current="page">List</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 overflow-auto flex-fill">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="bg-light p-3 mt-3">
                            <div className="row align-items-start">
                                <div className="col-12">
                                    <h5 className="mb-0 text-black fw-semibold ">Users List</h5>
                                </div>


                            </div>
                            <br />
                            <br />
                            <div className="row">

                                <Row>

                                    <Col>
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            User Name
                                        </label>
                                        <div className="input-group w-auto">
                                            <input type="text" name="licenseNumber" value={name}
                                                className="form-control text-black w-auto border-gray fs-6"
                                                onChange={(e) => setName(e.target.value.toLowerCase())}></input>

                                        </div>
                                    </Col>
                                    <Col>
                                        <label htmlFor="inputPassword" className="text-black-50 fs-6 me-2 align-self-center">
                                            Email
                                        </label>
                                        <div className="input-group w-auto">
                                            <input type="text" name="licenseNumber" value={email}
                                                className="form-control text-black w-auto border-gray fs-6"
                                                onChange={(e) => setEmail(e.target.value.toLowerCase())}></input>

                                        </div>
                                    </Col>

                                    <Col className="mt-4">
                                        <Button variant="primary" className="me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button" onClick={submitSearch} >
                                            Search
                                        </Button>
                                        <Button variant="primary" className="me-4 fs-6 fw-medium rounded-pill w-130" size="lg" type="button" onClick={onSearchClear} >
                                            Clear
                                        </Button>
                                    </Col>
                                </Row>



                                <div className="row align-items-start">
                                    <div className="p-3">
                                        <div className="col-12">
                                            <Table responsive className="table table-responsive" hover size="sm" >
                                                <thead className="bg-dark text-muted text-nowrap">
                                                    <tr >
                                                        <th style={{ color: " #ffff", fontWeight: "bold", paddingBottom: "5px" }} variant="default">S.No</th>
                                                        <th className="p-0" scope="col">
                                                            <Button
                                                                variant="default"
                                                                className="btn btn-dark"
                                                                type="button"
                                                                onClick={() => {
                                                                    getUsersList(name, email, page, sizePerPage, "user_id", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                ID
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0">
                                                            <Button data-bs-toggle="modal"
                                                                role="button"
                                                                variant="default"
                                                                className="btn btn-dark"
                                                                type="button"
                                                                onClick={() => {
                                                                    getUsersList(name, email, page, sizePerPage, "first_name", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                Name
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th className="p-0">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersList(name, email, page, sizePerPage, "email", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                Email
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th scopa="col" style={{ color: " #ffff", fontWeight: "bold", paddingBottom: "5px" }} variant="default">
                                                            Status
                                                        </th>
                                                        <th className="p-0">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersList(name, email, page, sizePerPage, "userdevice_count", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                # of Logged-In Devices
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>

                                                        <th className="p-0">
                                                            <Button
                                                                variant="default"
                                                                type="button"
                                                                className="btn btn-dark"
                                                                onClick={() => {
                                                                    getUsersList(name, email, page, sizePerPage, "userroute_count", sortType === "desc" ? "asc" : "desc");
                                                                }}
                                                            >
                                                                # of routes created
                                                                <span className="fs-6 m-2">
                                                                    <i
                                                                        className={`${sortType === "desc"
                                                                            ? "fa fa-fw fa-sort-desc"
                                                                            : "fa fa-fw fa-sort-asc"
                                                                            }`}
                                                                    ></i>
                                                                </span>
                                                            </Button>
                                                        </th>
                                                        <th scopa="col" style={{ color: " #ffff", fontWeight: "bold", paddingBottom: "5px" }} variant="default">
                                                            More Options
                                                        </th>


                                                    </tr>
                                                </thead>
                                                {isLoading ?
                                                    <tbody>
                                                        <ThreeDots
                                                            height="80"
                                                            width="80"
                                                            radius="9"
                                                            color="#0275d8"
                                                            ariaLabel="Loading Copmanies list"
                                                            wrapperStyle={{ position: "fixed", left: "50%" }}
                                                            wrapperClassName=""
                                                            visible={true}
                                                        />
                                                    </tbody> :
                                                    userList && userList.length > 0 ? (
                                                        <tbody className="text-black-50 bg-light " >
                                                            {userList.map((info, index) => {
                                                                return (
                                                                    <tr style={{ cursor: "pointer" }} className="table-light">
                                                                        <td >{(page - 1) * 10 + (index + 1)}</td>
                                                                        <td onClick={() => linkFormatter(info?.user_id)}>{info.user_id}</td>
                                                                        <td onClick={() => linkFormatter(info?.user_id)}>{info.first_name + " " + info.last_name}</td>

                                                                        <td onClick={() => linkFormatter(info?.user_id)}>{info.email}</td>
                                                                        <td onClick={() => {
                                                                            setModalPopup(true)
                                                                            setDeleteInfo(info)
                                                                            setuserSoftDelete(info.is_active === 1 ? "deactive" : "active")
                                                                        }
                                                                        }>
                                                                            {info.is_active === 1 ? (
                                                                                <>
                                                                                    <Checkmark size='20px' color='green' />
                                                                                </>
                                                                            ) : (
                                                                                <Checkmark size='20px' color='red' />
                                                                            )}
                                                                        </td>
                                                                        <td onClick={() => linkFormatter(info?.user_id)} className="text-center">{info.userdevice_count}</td>
                                                                        <td className="text-center" > <button type="button" class="btn btn-link" onClick={() => linkUseroutes(info?.user_id)} >{info.userroute_count}</button></td>
                                                                        <td onClick={() => {
                                                                            toggleDropdown()
                                                                            setMenuInfo(info)
                                                                        }}><IoMdMore />
                                                                            {dropdownOpen && (menuInfo.user_id === info.user_id) && (
                                                                                <div ref={dropdownRef} style={{ borderWidth: "1px", borderRadius: "5px", position: "absolute", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", backgroundColor: "white" }}>
                                                                                    <span className="px-2" onClick={() => sendResetPasswordOtp(info?.email)}>Resend Verification Code</span>
                                                                                </div>
                                                                            )}
                                                                        </td>

                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    ) : (
                                                        <tbody >
                                                            <tr>
                                                                <td colspan="6" align="center"><div className="col-12">No Data found</div></td>
                                                            </tr>

                                                        </tbody>
                                                    )}
                                            </Table>
                                        </div>

                                        <div className="p-3 col-12" key={remountPagination}>
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="next >"
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={1}
                                                //marginPagesDisplayed={2}
                                                pageCount={pageCount}
                                                previousLabel="< previous"
                                                renderOnZeroPageCount={null}
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"

                                            />

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>

    );
};

export default UsersList;
